import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Hide,
  Show,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import ChainSelector from 'components/ChainSelector'
import ChainTabs from 'components/ChainTabs'
import SortableTableHeader from 'components/SortableTableHeader'
import { supportedChains } from 'constants/chains'
import useChainId from 'hooks/useChainId'
import useTopTradedTokens, { TokensSortType } from 'hooks/useTopTradedTokens'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { convertTokensToTokenRows } from 'utils/tokensRanking'

import TokensRankingMobileRow from './TokensRankingMobileRow'
import TokensRankingRow from './TokensRankingRow'

const TokensRanking = () => {
  const activeChainId = useChainId()
  const selectableChains = useMemo(
    () =>
      supportedChains.filter(
        (chain) =>
          !chain.testnet &&
          chain.id !== ChainId.BNB_CHAIN &&
          chain.id !== ChainId.BNB_TESTNET &&
          chain.id !== ChainId.ETHEREUM
      ),
    []
  )
  const [selectedChainIndex, setSelectedChainIndex] = useState(
    selectableChains.findIndex((chain) => chain.id === activeChainId)
  )
  const chainId = useMemo(
    () => selectableChains[selectedChainIndex]?.id ?? activeChainId,
    [selectedChainIndex, activeChainId, selectableChains]
  )

  const [sortMethod, setSortMethod] = useState<TokensSortType>('volume')
  const [isSortDescending, setIsSortDescending] = useState<boolean>(true)
  const [showAllMobile, setShowAllMobile] = useState(false)

  const { data: tokens = [], isLoading } = useTopTradedTokens({
    chainId,
    sortType: sortMethod
  })
  const tokenRows = useMemo(() => {
    const sortedTokens = tokens.sort((tokenA, tokenB) => {
      const [a, b] = isSortDescending ? [tokenA, tokenB] : [tokenB, tokenA]
      switch (sortMethod) {
        case 'volume':
          return b.volume - a.volume
        case 'price':
          return b.price - a.price
        case 'pct_change':
          return (b.pctChange ?? 0) - (a.pctChange ?? 0)
      }
    })
    return convertTokensToTokenRows(sortedTokens, chainId)
  }, [tokens, sortMethod, isSortDescending, chainId])

  const onHeaderClick = useCallback(
    (method: TokensSortType) => {
      sortMethod !== method
        ? setSortMethod(method)
        : setIsSortDescending((previous) => !previous)
    },
    [sortMethod]
  )

  useEffect(() => {
    setSelectedChainIndex(
      selectableChains.findIndex((chain) => chain.id === activeChainId)
    )
  }, [activeChainId, selectableChains])

  const displayedTokenRows = showAllMobile ? tokenRows : tokenRows.slice(0, 5)

  return (
    <Box w="full">
      <Flex
        gap={4}
        px={{ base: 4, md: 0 }}
        pb={4}
        align={{ base: 'flex-start', md: 'center' }}
        flexDir={{ base: 'column', md: 'row' }}
        justify={{ base: 'flex-start', md: 'space-between' }}
      >
        <Heading size="lg">
          <Trans>Top Traded</Trans>
        </Heading>
        <Hide below="md">
          <ChainTabs
            chains={selectableChains}
            index={selectedChainIndex}
            onChange={setSelectedChainIndex}
          />
        </Hide>
        <Show below="md">
          <ChainSelector
            selectedChainId={
              selectableChains[selectedChainIndex]?.id ?? chainId
            }
            onChainSelect={(chainId) =>
              setSelectedChainIndex(
                selectableChains.findIndex((c) => c.id === chainId)
              )
            }
            menuButtonProps={{ w: 'full' }}
          />
        </Show>
      </Flex>

      {isLoading ? (
        <Center h="120px">
          <Spinner />
        </Center>
      ) : (
        <>
          <Hide below="md">
            <TableContainer
              bg="bgCard"
              borderRadius="2xl"
              border="1px solid"
              borderColor="border"
              p={4}
            >
              <Table variant="card">
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>
                      <Trans>Token</Trans>
                    </Th>
                    <SortableTableHeader
                      name={t`Volume (24H)`}
                      isSortActive={sortMethod === 'volume'}
                      isSortDescending={isSortDescending}
                      isNumeric
                      onClick={() => onHeaderClick('volume')}
                    />
                    <SortableTableHeader
                      name={t`Price`}
                      isSortActive={sortMethod === 'price'}
                      isSortDescending={isSortDescending}
                      isNumeric
                      onClick={() => onHeaderClick('price')}
                    />
                    <SortableTableHeader
                      name={t`Change (24H)`}
                      isSortActive={sortMethod === 'pct_change'}
                      isSortDescending={isSortDescending}
                      isNumeric
                      onClick={() => onHeaderClick('pct_change')}
                    />
                  </Tr>
                </Thead>
                <Tbody>
                  {tokenRows.map((token, i) => {
                    return <TokensRankingRow {...token} key={i} />
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Hide>
          <Hide above="md">
            <TableContainer>
              <Table width="full">
                <Thead bg="bgSecondary">
                  <Tr>
                    <Th pr={4} py={2}>
                      Token
                    </Th>
                    <Th px={0} py={2}>
                      Volume
                    </Th>
                    <Th px={0} pr={4} py={2} textAlign="right">
                      Price
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {displayedTokenRows.map((token, i) => {
                    return <TokensRankingMobileRow key={i} {...token} />
                  })}
                </Tbody>
              </Table>
            </TableContainer>

            {!showAllMobile && tokenRows.length > 5 && (
              <Button
                onClick={() => setShowAllMobile(true)}
                w="full"
                variant="ghost"
              >
                View More Tokens
              </Button>
            )}
          </Hide>
        </>
      )}
    </Box>
  )
}

export default TokensRanking
