import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import useUserAPTFarmBalance from 'hooks/farm/useUserAPTFarmBalance'
import useUserFarmClaimableRewards from 'hooks/farm/useUserFarmClaimableReward'
import React from 'react'
import { Farm, FarmRewardToken } from 'types/dexbarn'
import { getFarmAndPoolId } from 'utils/farm'
import { formattedNum } from 'utils/format'
import { GetBalanceData } from 'wagmi/query'

import VaultInfoStats from '../VaultInfoStats'
import PoolRewardsBalance from './PoolRewardsBalance'
import PoolRewardsClaim from './PoolRewardsClaim'
import PoolRewardsDeposit from './PoolRewardsDeposit'
import PoolRewardsWithdraw from './PoolRewardsWithdraw'

interface VaultRewardsProps {
  farm: Farm
  balance?: GetBalanceData
  currency?: Currency
  refetchFarmBalances?: () => void
  userFarmDepositedBalance?: string
  vaultAddress?: string
}

const APTPoolRewards = ({
  balance,
  currency,
  farm,
  refetchFarmBalances,
  userFarmDepositedBalance,
  vaultAddress
}: VaultRewardsProps) => {
  const { farmAddress, poolId } = getFarmAndPoolId(farm.farmId)

  const {
    data,
    isLoading,
    refetch: refetchClaimableRewards
  } = useUserFarmClaimableRewards({
    farmAddress,
    poolId
  })

  const { data: userAPTBalanceUSD } = useUserAPTFarmBalance({ vaultAddress })

  const { pendingBonusTokenRewards, pendingJoeRewards } = data ?? {}
  const bonusRewardToken: FarmRewardToken | undefined = farm.rewarder
    ? {
        decimals: farm.rewarder.rewarderTokenDecimals,
        id: farm.rewarder.rewarderTokenId,
        name: farm.rewarder.rewarderTokenName,
        symbol: farm.rewarder.rewarderTokenSymbol
      }
    : undefined
  const hasPendingJoeRewards = pendingJoeRewards > BigInt(0)
  const hasPendingBonusTokenRewards = pendingBonusTokenRewards > BigInt(0)

  const secondsPerDay = 60 * 60 * 24
  const joeRewardsPerDay = secondsPerDay * farm.rewardsPerSec
  const bonusRewardPerDay = farm.rewarder
    ? secondsPerDay * farm.rewarder.rewarderTokenPerSec
    : undefined
  const formattedRewardsPerDay =
    `${formattedNum(joeRewardsPerDay, { places: 2 })} JOE` +
    (bonusRewardPerDay
      ? ` + ${formattedNum(bonusRewardPerDay, { places: 2 })} ${farm.rewarder
          ?.rewarderTokenSymbol}`
      : '')

  const totalApr1d = farm.apr1d + (farm.rewarder?.rewarderApr1d || 0)

  return (
    <VStack w="full">
      <Box w="full" bg="bgCard" borderRadius="2xl" borderColor="border">
        <VStack flexDirection="column" rowGap={{ base: 4, md: 6 }}>
          <Flex
            w="full"
            flexDir="column"
            pt={{ base: 4, md: 8 }}
            px={{ base: 4, md: 8 }}
          >
            <Flex flexDir="column" rowGap={1}>
              <Heading size="md">
                <Trans>Rewards</Trans>
              </Heading>
              <Text fontSize="sm" textColor="textSecondary">
                <Trans>Stake your APT tokens for additional rewards</Trans>
              </Text>
            </Flex>
            <Spacer mt={4} />
            <HStack columnGap={4}>
              <VaultInfoStats
                title={t`Liquidity (USD)`}
                value={formattedNum(farm.liquidityUsd, {
                  places: 2,
                  usd: true
                })}
              />
              <VaultInfoStats
                tooltipLabel={t`Rewards per day: ${formattedRewardsPerDay}`}
                title="APR (24H)"
                value={`${formattedNum(totalApr1d * 100, {
                  places: 2,
                  usd: false
                })}%`}
              />
            </HStack>
          </Flex>

          <Tabs isFitted w="full" variant="soft-rounded">
            <VStack px={{ base: 4, md: 8 }}>
              <TabList w="full">
                <Tab
                  _selected={{
                    bg: 'joeLight.400',
                    color: 'textPrimary'
                  }}
                >
                  <Trans>Deposit</Trans>
                </Tab>
                <Tab
                  _selected={{
                    bg: 'joeLight.400',
                    color: 'textPrimary'
                  }}
                >
                  <Trans>Withdraw</Trans>
                </Tab>
              </TabList>
            </VStack>
            <TabPanels
              px={{ base: 4, md: 8 }}
              pb={userFarmDepositedBalance ? 0 : 4}
            >
              <TabPanel px={0}>
                <PoolRewardsDeposit
                  balance={balance}
                  currency={currency}
                  farmAddress={farmAddress}
                  poolId={poolId}
                  refetchFarmBalances={refetchFarmBalances}
                />
              </TabPanel>
              <TabPanel px={0}>
                <PoolRewardsWithdraw
                  balance={userFarmDepositedBalance}
                  currency={currency}
                  farmAddress={farmAddress}
                  poolId={poolId}
                  refetchFarmBalances={refetchFarmBalances}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>

        {userFarmDepositedBalance ? (
          <PoolRewardsBalance
            balance={userFarmDepositedBalance}
            balanceUSD={userAPTBalanceUSD}
            currency={currency}
          />
        ) : null}

        {!isLoading &&
          (hasPendingJoeRewards || hasPendingBonusTokenRewards) && (
            <>
              <Divider />
              <PoolRewardsClaim
                poolId={poolId}
                farmAddress={farmAddress}
                rewardTokens={
                  bonusRewardToken
                    ? [farm.reward, bonusRewardToken]
                    : [farm.reward]
                }
                pendingRewards={
                  bonusRewardToken
                    ? [pendingJoeRewards, pendingBonusTokenRewards]
                    : [pendingJoeRewards]
                }
                refetchAll={() => {
                  refetchFarmBalances?.()
                  refetchClaimableRewards()
                }}
              />
            </>
          )}
      </Box>
    </VStack>
  )
}

export default APTPoolRewards
