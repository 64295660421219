import {
  Box,
  Center,
  Divider,
  Flex,
  SimpleGrid,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { CNATIVE } from '@traderjoe-xyz/sdk-core'
import { VaultABI } from '@traderjoe-xyz/sdk-v2'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import LBPairDistributionSection from 'components/LBPairDistributionSection'
import PageHelmet from 'components/PageHelmet'
import { FARM_HELMET_DESCRIPTION, FARM_HELMET_TITLE } from 'constants/vaults'
import useGetUserFarmPosition from 'hooks/farm/useGetUserFarmPosition'
import useLBPairUserDistribution from 'hooks/pool/v2/useLBPairUserDistribution'
import useChainId from 'hooks/useChainId'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import { useTokenBalance } from 'hooks/useTokenBalance'
import useUserVaultBalances from 'hooks/vault/useUserVaultBalances'
import useVault from 'hooks/vault/useVault'
import useVaultQueuedWithdrawals from 'hooks/vault/useVaultQueuedWithdrawals'
import debounce from 'lodash.debounce'
import PoolDetailHeader from 'pages/PoolDetail/PoolDetailHeader'
import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  getCurrencyAddress,
  isWrappedNativeCurrency
} from 'utils/wrappedCurrency'
import { getAddress } from 'viem'
import { useReadContract } from 'wagmi'

import APTPoolRewards from './APTPoolRewards'
import VaultAddRemoveLiquidity from './VaultAddRemoveLiquidity'
import VaultAnalytics from './VaultAnalytics'
import VaultInfo from './VaultInfo'
import VaultQueuedWithdrawalPanel from './VaultQueuedWithdrawalPanel'

const VaultDetail = () => {
  const { vaultAddress } = useParams()
  const chainId = useChainId()
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const { data: vault, isLoading: isLoadingVault } = useVault({ vaultAddress })

  const nativeCurrency = CNATIVE.onChain(chainId)
  const isToken0WrappedNative = isWrappedNativeCurrency(
    vault?.tokenX.address,
    chainId
  )
  const isToken1WrappedNative = isWrappedNativeCurrency(
    vault?.tokenY.address,
    chainId
  )

  const {
    isLoading: isLoadingCurrencies,
    tokens: [currency0, currency1, APToken]
  } = useSdkCurrencies({
    addresses: [
      isToken0WrappedNative ? nativeCurrency.symbol : vault?.tokenX.address,
      isToken1WrappedNative ? nativeCurrency.symbol : vault?.tokenY.address,
      vaultAddress
    ]
  })

  const { data: balance0, refetch: refetchTokenBalance0 } = useTokenBalance({
    token: getCurrencyAddress(currency0)
  })
  const { data: balance1, refetch: refetchTokenBalance1 } = useTokenBalance({
    token: getCurrencyAddress(currency1)
  })
  const { data: APTokenBalance, refetch: refetchAPTokenBalance } =
    useTokenBalance({
      token: getCurrencyAddress(APToken)
    })

  const { data: currentRound } = useReadContract({
    abi: VaultABI,
    address: vault ? getAddress(vault.id) : undefined,
    chainId,
    functionName: 'getCurrentRound',
    query: { enabled: !!vault }
  })

  const { data: userVaultsBalances, refetch: refetchUserVaultPosition } =
    useUserVaultBalances({
      vaults: vault ? [vault] : []
    })
  const userVaultBalances = userVaultsBalances?.[0]

  const { data: userFarmPosition, refetch: refetchUserFarmPosition } =
    useGetUserFarmPosition({
      vault
    })

  const { data: queuedWithdrawals, refetch: refetchQueuedWithdrawals } =
    useVaultQueuedWithdrawals({
      vaultAddress: vault?.id
    })

  const {
    activeBinId,
    chartData,
    isLoading: isLoadingVaultDistribution
  } = useLBPairUserDistribution({
    binStep: vault?.lbPair.binStep.toString(),
    currency0,
    currency1,
    enabled: !!vault && !!vault.strategy,
    lbPairAddress: vault?.lbPair.id,
    lbPairVersion: vault?.lbPair.version,
    owner: vault?.strategy?.id
  })

  const debouncedRefetchBalance0 = debounce(() => refetchTokenBalance0(), 4000)
  const debouncedRefetchBalance1 = debounce(() => refetchTokenBalance1(), 4000)
  const debouncedRefetchUserVaultPosition = debounce(
    () => refetchUserVaultPosition(),
    4000
  )
  const debouncedRefetchQueuedWithdrawals = debounce(
    () => refetchQueuedWithdrawals(),
    4000
  )
  const debouncedRefetchAPTokenBalance = debounce(
    () => refetchAPTokenBalance(),
    6000
  )
  const debouncedRefetchUserFarmPosition = debounce(
    () => refetchUserFarmPosition(),
    6000
  )

  const refetchAll = useCallback(() => {
    debouncedRefetchBalance0()
    debouncedRefetchBalance1()
    debouncedRefetchUserVaultPosition()
    debouncedRefetchAPTokenBalance()
  }, [
    debouncedRefetchBalance0,
    debouncedRefetchBalance1,
    debouncedRefetchUserVaultPosition,
    debouncedRefetchAPTokenBalance
  ])

  const refetchFarmBalances = useCallback(() => {
    debouncedRefetchAPTokenBalance()
    debouncedRefetchUserFarmPosition()
  }, [debouncedRefetchAPTokenBalance, debouncedRefetchUserFarmPosition])

  if (isLoadingVault || isLoadingCurrencies || !vault) {
    return (
      <Center minH="100vh">
        <Spinner />
      </Center>
    )
  }

  const isDepositDisabled = vault.isDisabled

  return (
    <Box pt={4} w="full" bg="joeLight.400" _dark={{ bg: 'joeDark.600' }}>
      <PageHelmet
        title={FARM_HELMET_TITLE}
        description={FARM_HELMET_DESCRIPTION}
        url={location.pathname}
      />
      <PoolDetailHeader
        address={vaultAddress}
        token0={{
          address:
            currency0?.isToken && !isToken0WrappedNative
              ? currency0.address
              : undefined,
          symbol: currency0?.isNative
            ? currency0?.symbol
            : vault?.tokenX.symbol ?? currency0?.symbol
        }}
        token1={{
          address:
            currency1?.isToken && !isToken1WrappedNative
              ? currency1.address
              : undefined,
          symbol: currency1?.isNative
            ? currency1?.symbol
            : vault?.tokenY.symbol ?? currency0?.symbol
        }}
        px={4}
        pb={{ base: 4, md: 8 }}
        isRewarded={vault.isRewarded}
      />
      <Tabs
        variant="enclosed"
        pos="relative"
        mt={{ base: 0, md: '-42px' }}
        w="full"
        onChange={setSelectedTab}
      >
        <TabList
          maxW={{ '2xl': '1600px', base: '1400px' }}
          margin="0 auto"
          px={4}
        >
          <Tab>
            <Trans>Manage</Trans>
          </Tab>
          <Tab>
            <Trans>Analytics</Trans>
          </Tab>
        </TabList>
        <TabPanels w="full" minH="calc(100vh - 250px)">
          <TabPanel>
            <SimpleGrid
              maxW={{ '2xl': '1600px', base: '1400px' }}
              margin="0 auto"
              minH="80vh"
              alignItems="flex-start"
              rowGap={{ base: 4, md: 8 }}
              columnGap={{ base: 4, md: 8 }}
              columns={{ base: 1, lg: 2 }}
              p={{ base: 0, md: 4 }}
            >
              <Flex flexDir="column" gap={4} order={{ base: 1, lg: 0 }}>
                <VaultInfo vault={vault} />
                {vault.strategy ? (
                  <Box
                    bg="bgCard"
                    borderRadius="2xl"
                    border="1px solid"
                    borderColor="border"
                    p={{ base: 4, md: 8 }}
                  >
                    <LBPairDistributionSection
                      activeBinId={activeBinId}
                      title={t`Auto Pool Liquidity`}
                      data={chartData}
                      isLoading={isLoadingVaultDistribution}
                      currency0={currency0}
                      currency1={currency1}
                    />
                  </Box>
                ) : null}
              </Flex>
              <Flex flexDir="column" gap={{ base: 4, md: 8 }}>
                <Box
                  bg="bgCard"
                  borderRadius="2xl"
                  border="1px solid"
                  borderColor="border"
                >
                  <VaultAddRemoveLiquidity
                    vaultAddress={vault.id}
                    currency0={currency0}
                    currency1={currency1}
                    balance0={balance0}
                    balance1={balance1}
                    onAddLiquiditySuccess={refetchAll}
                    onRemoveLiquiditySuccess={() => {
                      refetchAll()
                      debouncedRefetchQueuedWithdrawals()
                    }}
                    depositDisabled={isDepositDisabled}
                  />
                </Box>
                {vault.tokenX.address &&
                vault.tokenY.address &&
                userVaultBalances ? (
                  <Box
                    w="full"
                    bg="bgCard"
                    borderRadius="2xl"
                    border="1px solid"
                    borderColor="border"
                    p={{ base: 4, md: 8 }}
                  >
                    <CurrencyAmountsPanel
                      boxProps={{ px: 0, py: 0 }}
                      title={t`Deposit Balance`}
                      tokens={[vault.tokenX.address, vault.tokenY.address]}
                      tokenSymbols={[currency0?.symbol, currency1?.symbol]}
                      tokenAmounts={
                        userVaultBalances.amountX && userVaultBalances.amountY
                          ? [
                              userVaultBalances.amountX,
                              userVaultBalances.amountY
                            ]
                          : [0, 0]
                      }
                    />
                    {queuedWithdrawals && queuedWithdrawals.length > 0 ? (
                      <VStack>
                        {queuedWithdrawals.map((queuedWithdrawal, i) => (
                          <Box key={i} w="full">
                            <Divider my={{ base: 4, md: 6 }} />
                            <VaultQueuedWithdrawalPanel
                              currency0={currency0}
                              currency1={currency1}
                              currentRound={currentRound}
                              queuedWithdrawal={queuedWithdrawal}
                            />
                          </Box>
                        ))}
                      </VStack>
                    ) : null}
                  </Box>
                ) : null}
                {vault.farm ? (
                  <Box
                    bg="bgCard"
                    borderRadius="2xl"
                    border="1px solid"
                    borderColor="border"
                  >
                    <APTPoolRewards
                      farm={vault.farm}
                      userFarmDepositedBalance={userFarmPosition?.formatted}
                      currency={APToken}
                      balance={APTokenBalance}
                      vaultAddress={vaultAddress}
                      refetchFarmBalances={refetchFarmBalances}
                    />
                  </Box>
                ) : null}
              </Flex>
            </SimpleGrid>
          </TabPanel>
          <TabPanel
            pb={32}
            pt={8}
            maxW={{ '2xl': '1600px', base: '1400px' }}
            margin="0 auto"
          >
            <VaultAnalytics
              vault={vault}
              isTabSelected={selectedTab === 1}
              currency0={currency0}
              currency1={currency1}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default VaultDetail
