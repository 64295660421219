import axios, { CreateAxiosDefaults } from 'axios'

const JOEBARN_URL = process.env.REACT_APP_JOE_BARN_URL

if (typeof JOEBARN_URL === 'undefined') {
  throw new Error(
    'REACT_APP_JOE_BARN_URL must be a defined environment variable'
  )
}

const JOEBARN_TESTNET_URL = process.env.REACT_APP_JOE_BARN_TESTNET_URL
if (typeof JOEBARN_URL === 'undefined') {
  throw new Error(
    'REACT_APP_JOE_BARN_TESTNET_URL must be a defined environment variable'
  )
}

const clientConfig: CreateAxiosDefaults = {
  headers: { accept: 'application/json' },
  withCredentials: true
}

export const joebarnClient = axios.create({
  ...clientConfig,
  baseURL: JOEBARN_URL
})

export const joebarnTestnetClient = axios.create({
  ...clientConfig,
  baseURL: JOEBARN_TESTNET_URL
})

export const apiGatewayClient = axios.create({
  ...clientConfig,
  baseURL: `https://api-gateway.${window.location.host}`
})

export const apiGatewayTestnetClient = axios.create({
  ...clientConfig,
  baseURL: `https://api-gateway-testnet.${window.location.host}`
})
