import 'swiper/css'

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Heading,
  Hide,
  Image,
  SimpleGrid,
  Text
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { CollectionItem } from 'types/joebarn'
import { formattedNum } from 'utils/format'
import { formatUnits } from 'viem'

interface JoepegPreviewProps {
  collections?: CollectionItem[]
}

const JoepegPreview = ({ collections }: JoepegPreviewProps) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null)

  const handleNext = () => {
    if (swiper) {
      swiper.slideNext()
    }
  }

  const handlePrevious = () => {
    if (swiper) {
      swiper.slidePrev()
    }
  }

  return (
    <Box pos="relative">
      <Box
        as={Swiper}
        w="full"
        h="full"
        onSwiper={setSwiper}
        navigation={true}
        slidesPerGroup={1}
        spaceBetween="8px"
        breakpoints={{
          [480]: {
            slidesPerView: 1
          },
          [768]: {
            slidesPerView: 3
          },
          [992]: {
            slidesPerView: 4
          }
        }}
      >
        {collections?.map((item, idx) => (
          <SwiperSlide key={idx}>
            <Flex
              mt={{ base: 0, md: '4px' }}
              bg="bgCard"
              border="1px solid"
              borderColor="border"
              p={4}
              as={Link}
              w="full"
              justifyContent="center"
              flexDir="column"
              borderRadius="2xl"
              rowGap={4}
              _hover={{
                cursor: 'pointer',
                textDecor: 'none',
                transform: 'translateY(-4px)'
              }}
              to={`https://www.joepegs.com/collections/${item.chain}/${item.collectionId}`}
              transition="all 0.3s"
            >
              <AspectRatio ratio={2}>
                <Image
                  src={item.collectionPfpUrl}
                  h="100%"
                  w="100%"
                  borderRadius="2xl"
                />
              </AspectRatio>
              <Flex
                alignItems="center"
                justifyContent="flex-start"
                columnGap={3}
              >
                <Image
                  borderRadius="1rem"
                  boxSize="48px"
                  src={item.collectionPfpUrl}
                  objectFit="contain"
                />
                <Flex flexDir="column">
                  <Heading size="sm">{item.collectionName}</Heading>
                  <Text fontSize="xs" textColor="textSecondaryLight">
                    {formattedNum(item.numItems, { places: 0, usd: false })}{' '}
                    <Trans>items</Trans>
                  </Text>
                </Flex>
              </Flex>
              <SimpleGrid columns={2}>
                <Flex flexDir="column">
                  <Text fontSize="xs" textColor="textSecondaryLight">
                    <Trans>Floor</Trans>
                  </Text>
                  <Text fontWeight="semibold">
                    {formattedNum(formatUnits(item.floorPrice, 18), {
                      places: 2,
                      usd: false
                    })}{' '}
                    AVAX
                  </Text>
                </Flex>
                <Flex flexDir="column">
                  <Text fontSize="xs" textColor="textSecondaryLight">
                    <Trans>Volume (7D)</Trans>
                  </Text>
                  <Text fontWeight="semibold">
                    {formattedNum(formatUnits(item.volume7d, 18), {
                      places: 0,
                      usd: false
                    })}{' '}
                    AVAX
                  </Text>
                </Flex>
              </SimpleGrid>
            </Flex>
          </SwiperSlide>
        ))}
      </Box>
      <Hide below="md">
        {collections && collections.length > 4 && (
          <>
            <Button
              _light={{ _hover: { bg: 'joeLight.400' }, bg: 'joeLight.500' }}
              _dark={{ _hover: { bg: 'joeDark.400' }, bg: 'joeDark.500' }}
              onClick={handlePrevious}
              position="absolute"
              top="calc(50% - 20px)"
              left="-16px"
              w="40px"
              h="40px"
              borderRadius="50%"
              zIndex={1}
            >
              <ChevronLeftIcon boxSize="24px" />
            </Button>
            <Button
              _light={{ _hover: { bg: 'joeLight.400' }, bg: 'joeLight.500' }}
              _dark={{ _hover: { bg: 'joeDark.400' }, bg: 'joeDark.500' }}
              onClick={handleNext}
              position="absolute"
              top="calc(50% - 20px)"
              right="-16px"
              w="40px"
              h="40px"
              borderRadius="50%"
              zIndex={1}
            >
              <ChevronRightIcon boxSize="24px" />
            </Button>
          </>
        )}
      </Hide>
    </Box>
  )
}

export default JoepegPreview
