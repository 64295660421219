import { useQuery } from '@tanstack/react-query'
import { useDexbarnPost } from 'hooks/useDexbarn'
import { TokenPrice } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'

import useChainId from './useChainId'

interface UseGetTokenUsdPriceProps {
  tokenAddress?: string
}

const useGetTokenUsdPrice = ({ tokenAddress }: UseGetTokenUsdPriceProps) => {
  const chainId = useChainId()

  const fetchPrices = useDexbarnPost<TokenPrice[]>(
    `/v1/tokens/${getDexbarnChainParam(chainId)}/prices`,
    { tokenAddresses: [tokenAddress] }
  )

  return useQuery({
    enabled: !!tokenAddress,
    queryFn: () => fetchPrices(),
    queryKey: ['GetTokenUsdPrice', tokenAddress],
    select: (data) => {
      return parseFloat(data[0].priceUsd)
    }
  })
}

export default useGetTokenUsdPrice
