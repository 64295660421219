import { ChainId, Currency, WNATIVE } from '@traderjoe-xyz/sdk-core'
import { getCurrencyAddress } from 'utils/wrappedCurrency'

import { DexbarnCandle } from './candles'

export function getAddress(
  chainId: Exclude<ChainId, ChainId.MANTLE>,
  token: Currency | undefined
): string | undefined {
  if (!token) {
    return undefined
  }

  return getCurrencyAddress(
    token.isNative ? WNATIVE[chainId] : token
  )?.toLowerCase()
}

export const mergeDexCandleResults = (
  candlesA: DexbarnCandle[],
  candlesB: DexbarnCandle[],
  wNativeAddress: string
) => {
  const candles: DexbarnCandle[] = []

  const asset0 =
    wNativeAddress.toLowerCase() === candlesA[0].quoteAsset.toLowerCase()
      ? candlesA[0].baseAsset
      : candlesA[0].quoteAsset

  const asset1 =
    wNativeAddress.toLowerCase() === candlesB[0].quoteAsset.toLowerCase()
      ? candlesB[0].baseAsset
      : candlesB[0].quoteAsset

  const quoteAsset = asset0 === candlesA[0].quoteAsset ? asset1 : asset0
  const baseAsset = asset0 === candlesA[0].quoteAsset ? asset0 : asset1

  const candles1ByTime = candlesB.reduce(
    (prev: { [time: number]: DexbarnCandle }, curr) => {
      prev[curr.timestamp] = curr
      return prev
    },
    {}
  )

  let lastCandle1: DexbarnCandle | undefined
  candlesA.forEach((candle0) => {
    const candle1 = candles1ByTime[candle0.timestamp]
    // Use either matched candle or the last candle to calculate
    // the comparison candlestick data
    lastCandle1 = candle1 ?? lastCandle1
    if (!lastCandle1) {
      return
    }

    if (
      (candlesA[0]?.quoteAsset === wNativeAddress.toLowerCase() &&
        candlesB[0]?.baseAsset === wNativeAddress.toLowerCase()) ||
      (candlesB[0]?.quoteAsset === wNativeAddress.toLowerCase() &&
        candlesA[0]?.baseAsset === wNativeAddress.toLowerCase())
    ) {
      candles.push({
        baseAsset,
        close: 1 / lastCandle1.close / candle0.close,
        high: 1 / lastCandle1.high / candle0.high,
        low: 1 / lastCandle1.low / candle0.low,
        open: 1 / lastCandle1.open / candle0.open,
        quoteAsset,
        timestamp: candle0.timestamp
      })
    } else {
      candles.push({
        baseAsset,
        close: lastCandle1.close / candle0.close,
        high: lastCandle1.high / candle0.high,
        low: lastCandle1.low / candle0.low,
        open: lastCandle1.open / candle0.open,
        quoteAsset,
        timestamp: candle0.timestamp
      })
    }
  })

  return candles
}
