import { Show } from '@chakra-ui/react'
import AccountBlockedModal from 'components/AccountBlockedModal'
import { useAnnouncements } from 'components/Announcements'
import Footer from 'components/Footer'
import Header from 'components/Header'
import MobileBottomTabBar from 'components/MobileBottomTabBar'
import ScrollToTop from 'components/ScrollToTop'
import useChainFromUrlParam from 'hooks/useChainFromUrlParam'
import useLoadOriginCountry from 'hooks/useLoadOriginCountry'
import useOnWalletConnectListeners from 'hooks/useOnWalletConnectListeners'
import NotFound from 'pages/404'
import ClaimRewardsV1 from 'pages/ClaimRewardsV1'
import CreatePool from 'pages/CreatePool'
import CreatePoolV2 from 'pages/CreatePoolV2'
import Home from 'pages/Home'
import MemeFarmDetail from 'pages/MemeFarmDetail'
import MemeFarms from 'pages/MemeFarms'
import Pool from 'pages/Pool'
import PoolDetail from 'pages/PoolDetail'
import PoolDetailV2 from 'pages/PoolDetailV2'
import { PoolDetailV2ContextProvider } from 'pages/PoolDetailV2/state'
import Stake from 'pages/Stake'
import SJoeDetail from 'pages/Stake/SJoeDetail'
import VaultDetail from 'pages/VaultDetail'
import React, { useEffect } from 'react'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate
} from 'react-router-dom'
import { useSelectedChainId } from 'state/settings/hooks'
import { getChainSlug } from 'utils/chains'

import Privacy from './pages/Legal/Privacy'
import TermsOfService from './pages/Legal/TermsOfService'
import Trade from './pages/Trade'

const ElementWithHeader = ({
  element,
  validateChainSlug = true
}: {
  element: JSX.Element
  validateChainSlug?: boolean
}) => {
  const navigate = useNavigate()
  const chain = useChainFromUrlParam()

  useEffect(() => {
    // make sure the chain in the url is supported, else we redirect to 404 page
    if (!chain && validateChainSlug) {
      navigate('/404')
    }
  }, [chain, navigate, validateChainSlug])
  return (
    <>
      <Header />
      {element}
    </>
  )
}

export const App = () => {
  const { selectedChainId } = useSelectedChainId()
  useAnnouncements()
  useOnWalletConnectListeners()
  useLoadOriginCountry()

  return (
    <BrowserRouter>
      <ScrollToTop />
      <AccountBlockedModal />
      <Routes>
        <Route
          path="/:chainSlug"
          element={<ElementWithHeader element={<Home />} />}
        />
        <Route
          path="/:chainSlug/trade"
          element={<ElementWithHeader element={<Trade />} />}
        />
        <Route
          path="/:chainSlug/pool"
          element={<ElementWithHeader element={<Pool />} />}
        />
        <Route
          path="/:chainSlug/pool/v1/:token0Address/:token1Address/"
          element={<ElementWithHeader element={<PoolDetail />} />}
        />
        <Route
          path="/:chainSlug/pool/v1/create"
          element={<ElementWithHeader element={<CreatePool />} />}
        />
        <Route
          path="/:chainSlug/pool/v1/create/:token0Address/:token1Address"
          element={<ElementWithHeader element={<CreatePool />} />}
        />
        <Route
          path="/:chainSlug/pool/v2/create"
          element={<ElementWithHeader element={<CreatePoolV2 />} />}
        />
        <Route
          path="/:chainSlug/pool/:poolVersion/:currencyId0/:currencyId1/:binStep"
          element={
            <ElementWithHeader
              element={
                <PoolDetailV2ContextProvider>
                  <PoolDetailV2 />
                </PoolDetailV2ContextProvider>
              }
            />
          }
        />
        <Route
          path="/:chainSlug/vault/:vaultAddress"
          element={<ElementWithHeader element={<VaultDetail />} />}
        />
        <Route
          path="/:chainSlug/stake"
          element={<ElementWithHeader element={<Stake />} />}
        />

        <Route
          path="/:chainSlug/stake/sjoe"
          element={<ElementWithHeader element={<SJoeDetail />} />}
        />
        <Route
          path="/:chainSlug/stek"
          element={<ElementWithHeader element={<MemeFarms />} />}
        />
        <Route
          path="/:chainSlug/stek/:tokenAddress"
          element={<ElementWithHeader element={<MemeFarmDetail />} />}
        />
        <Route
          path="/:chainSlug/v1/rewards/claim"
          element={<ElementWithHeader element={<ClaimRewardsV1 />} />}
        />
        <Route
          path="/privacy"
          element={
            <ElementWithHeader
              element={<Privacy />}
              validateChainSlug={false}
            />
          }
        />
        <Route
          path="/tos"
          element={
            <ElementWithHeader
              element={<TermsOfService />}
              validateChainSlug={false}
            />
          }
        />
        <Route
          path="/404"
          element={
            <ElementWithHeader
              element={<NotFound />}
              validateChainSlug={false}
            />
          }
        />
        {/* Redirect root paths to selected chain */}
        <Route
          path="/"
          element={
            <Navigate to={`/${getChainSlug(selectedChainId)}`} replace />
          }
        />
        <Route
          path="/pool"
          element={
            <Navigate to={`/${getChainSlug(selectedChainId)}/pool`} replace />
          }
        />
        <Route
          path="/trade"
          element={
            <Navigate to={`/${getChainSlug(selectedChainId)}/trade`} replace />
          }
        />
        <Route
          path="/stake"
          element={
            <Navigate to={`/${getChainSlug(selectedChainId)}/stake`} replace />
          }
        />
        <Route
          path="/stake/sjoe"
          element={
            <Navigate
              to={`/${getChainSlug(selectedChainId)}/stake/sjoe`}
              replace
            />
          }
        />
        {/* Unknown paths */}
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
      <Footer />
      <Show below="lg">
        <MobileBottomTabBar />
      </Show>
    </BrowserRouter>
  )
}
