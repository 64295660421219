import { Center, Link, MenuButtonProps, MenuItem } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import ChainSelector from 'components/ChainSelector'
import useChainChangeListener from 'hooks/useChainChangeListener'
import useChainFromUrlParam from 'hooks/useChainFromUrlParam'
import useSwitchChain from 'hooks/useSwitchChain'
import React from 'react'
import { useSelectedChainId } from 'state/settings/hooks'
import { chainClickHelp, chainSwitchChain } from 'utils/measure'

const CHAIN_HELP_URL = `https://support.${window.location.host}/en/articles/6868048-what-is-a-bridge-and-why-use-one`

interface AppChainSelectorProps {
  menuButtonProps?: MenuButtonProps
}

const AppChainSelector = ({ menuButtonProps }: AppChainSelectorProps) => {
  const { switchChain } = useSwitchChain()
  const { updateChain } = useChainChangeListener()

  const { selectedChainId: initialChainId } = useSelectedChainId()
  const chainInUrlParam = useChainFromUrlParam()
  const selectedChainId = chainInUrlParam?.id ?? initialChainId

  return (
    <ChainSelector
      menuButtonProps={menuButtonProps}
      selectedChainId={selectedChainId ?? initialChainId}
      onChainSelect={(chainId) => {
        updateChain(chainId)
        switchChain({ chainId })
        chainSwitchChain(chainId)
      }}
      additionalMenuItems={[
        <MenuItem key="how-to-bridge" h="48px">
          <Center
            as={Link}
            justifyContent="flex-start"
            w="full"
            h="full"
            isExternal
            href={CHAIN_HELP_URL}
            onClick={() => {
              chainClickHelp()
            }}
          >
            <Trans>How to Bridge?</Trans>
          </Center>
        </MenuItem>
      ]}
    />
  )
}

export default AppChainSelector
