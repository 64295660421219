import {
  Box,
  Divider,
  Grid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { Currency, Token } from '@traderjoe-xyz/sdk-core'
import useGetOrders from 'hooks/limitOrder/useGetOrders'
import debounce from 'lodash.debounce'
import React from 'react'
import { GetBalanceData } from 'wagmi/query'

import ActiveOrders from './ActiveOrders'
import AddOrdersPanel from './AddOrdersPanel'
import CancelOrdersPanel from './CancelOrdersPanel'

interface PoolLimitOrdersProps {
  isPriceRatioInversed: boolean
  activeBinId?: number
  balance0?: GetBalanceData
  balance1?: GetBalanceData
  binStep?: string
  currency0?: Currency
  currency1?: Currency
  lbPairAddr?: string
  togglePriceRatiosClicked?: () => void
  tokenX?: Token
  tokenY?: Token
}

const PoolLimitOrders = (props: PoolLimitOrdersProps) => {
  const { lbPairAddr } = props

  const isPlaceOrdersEnabled = false

  const {
    isLoading: isLoadingPlacedOrders,
    orders: placedOrders,
    refetch: refetchPlacedOrders
  } = useGetOrders({
    lbPairAddr,
    pageSize: 100,
    status: 'placed'
  })

  const {
    isLoading: isLoadedExecutedOrders,
    orders: executedOrders,
    refetch: refetchExecutedOrders
  } = useGetOrders({
    lbPairAddr,
    pageSize: 100,
    status: 'executed'
  })

  const debouncedRefetchOrders = debounce(() => {
    refetchPlacedOrders()
    refetchExecutedOrders()
  }, 3000)

  return (
    <Grid
      templateColumns={{
        base: 'minmax(0, 1fr)',
        xl: 'minmax(0, 1fr) minmax(0, 1fr)'
      }}
      gap={{ base: 4, md: 8 }}
      alignItems="flex-start"
    >
      <ActiveOrders
        {...props}
        placedOrders={placedOrders}
        executedOrders={executedOrders}
        isLoadingOrders={isLoadedExecutedOrders || isLoadingPlacedOrders}
        onClaimOrdersSuccess={debouncedRefetchOrders}
      />
      <Box
        bg="bgCard"
        borderRadius="2xl"
        border="1px solid"
        borderColor="border"
      >
        <Tabs
          isFitted
          variant="soft-rounded"
          w="full"
          defaultIndex={isPlaceOrdersEnabled ? 0 : 1}
        >
          <TabList my={4} mx={{ base: 4, md: 8 }}>
            <Tooltip
              label={t`New orders are disabled`}
              isDisabled={isPlaceOrdersEnabled}
            >
              <Tab
                data-cy="add-orders-tab"
                _selected={{ bg: 'joeLight.400', color: 'textPrimary' }}
                isDisabled={!isPlaceOrdersEnabled}
              >
                Add Orders
              </Tab>
            </Tooltip>
            <Tab
              data-cy="remove-orders-tab"
              _selected={{ bg: 'joeLight.400', color: 'textPrimary' }}
            >
              Cancel Orders
            </Tab>
          </TabList>
          <Divider />
          <TabPanels py={{ base: 4, md: 6 }} px={{ base: 4, md: 8 }}>
            <TabPanel data-cy="add-orders-panel" p={0}>
              <AddOrdersPanel
                {...props}
                onPlaceOrdersSuccess={debouncedRefetchOrders}
              />
            </TabPanel>
            <TabPanel data-cy="remove-orders-panel" p={0}>
              <CancelOrdersPanel
                {...props}
                placedOrders={placedOrders}
                isLoadingOrders={isLoadingPlacedOrders}
                onCancelOrdersSuccess={debouncedRefetchOrders}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Grid>
  )
}

export default PoolLimitOrders
