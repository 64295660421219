import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { WalletMenuEnum } from 'types/walletMenu'

import ActivityScreen from './ActivityScreen'
import ConnectedScreen from './ConnectedScreen'
import SettingScreen from './SettingScreen'

interface WalletMenuProps {
  walletAddress: string
  accountDisplayName?: string
}

const WalletMenu = ({ accountDisplayName, walletAddress }: WalletMenuProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [walletMenuDisplay, setWalletMenuDisplay] = useState<WalletMenuEnum>(
    WalletMenuEnum.Default
  )

  const renderWalletMenu = () => {
    switch (walletMenuDisplay) {
      case WalletMenuEnum.Default:
        return (
          <ConnectedScreen
            isOpen={isOpen}
            walletAddress={walletAddress}
            setWalletMenuDisplay={setWalletMenuDisplay}
            onClose={onClose}
          />
        )
      case WalletMenuEnum.Settings:
        return (
          <SettingScreen
            boxProps={{ px: 6 }}
            headerStackProps={{ pb: 4, px: 3 }}
            onBackClick={() => setWalletMenuDisplay(WalletMenuEnum.Default)}
          />
        )
      case WalletMenuEnum.Activity:
        return (
          <ActivityScreen
            walletAddress={walletAddress}
            setWalletMenuDisplay={setWalletMenuDisplay}
          />
        )
    }
  }

  return (
    <Popover
      isOpen={isOpen}
      onOpen={() => {
        setWalletMenuDisplay(WalletMenuEnum.Default)
        onOpen()
      }}
      onClose={onClose}
      trigger="hover"
      placement="bottom-end"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button
          data-cy="wallet-menu-button"
          variant="outline"
          h="48px"
          borderRadius={'full'}
          rightIcon={<ChevronDownIcon />}
        >
          <Flex alignItems="center">{accountDisplayName}</Flex>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        py={4}
        minW="450px"
        zIndex={5}
        maxH="80vh"
        overflowY="auto"
      >
        {renderWalletMenu()}
      </PopoverContent>
    </Popover>
  )
}

export default WalletMenu
