import {
  Box,
  Center,
  Flex,
  Heading,
  SimpleGrid,
  Spinner,
  useToken
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import AnalyticsChart from 'components/AnalyticsChart'
import AnalyticsStat from 'components/AnalyticsStat'
import BinsTradedChart from 'components/BinsTradedChart'
import ChartZoomButtons from 'components/ChartZoomButtons'
import LBPairDistributionChart from 'components/LBPairDistributionChart'
import { POOL_DISTRIBUTION_CHART_RADIUS } from 'constants/pool'
import useBinAnalytics from 'hooks/analytics/useBinAnalytics'
import useLBPairDetailAnalytics from 'hooks/analytics/useLBPairDetailAnalytics'
import useLBPairSwaps from 'hooks/pool/v2/useLBPairSwaps'
import useChainId from 'hooks/useChainId'
import React, { useState } from 'react'
import { LBPoolRewards } from 'types/pool'
import { formattedNum } from 'utils/format'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { formatUnits } from 'viem'

import FeesBpsChart from './FeesBpsChart'
import PoolInfoTable from './PoolInfoTable'
import PoolSwapsTable from './PoolSwapsTable'

interface PoolDetailV2AnalyticsPanelProps {
  currency0: Currency
  currency1: Currency
  isPriceRatioInversed: boolean
  isSelected: boolean
  activeBinId?: number
  baseFee?: number
  lbPairAddress?: string
  liquidityDepthTokenX?: number
  liquidityDepthTokenY?: number
  reserveX?: bigint
  reserveY?: bigint
  rewards?: LBPoolRewards
}

const PoolDetailV2AnalyticsPanel = ({
  activeBinId,
  baseFee,
  currency0,
  currency1,
  isPriceRatioInversed,
  isSelected,
  lbPairAddress,
  liquidityDepthTokenX,
  liquidityDepthTokenY,
  reserveX,
  reserveY,
  rewards
}: PoolDetailV2AnalyticsPanelProps) => {
  const chainId = useChainId()
  const [graphPurple, graphGreen] = useToken('colors', [
    'graphPurpleDark',
    'graphGreenDark'
  ])

  const tokenA = wrappedCurrency(currency0, chainId)
  const tokenB = wrappedCurrency(currency1, chainId)

  const { data, isLoading } = useLBPairDetailAnalytics({
    enabled: isSelected,
    lbPairAddress
  })

  const { data: swaps } = useLBPairSwaps({
    address: lbPairAddress,
    enabled: isSelected,
    first: 10
  })

  const [distributionRadius, setDistributionRadius] = useState<number>(
    POOL_DISTRIBUTION_CHART_RADIUS.initial
  )
  const {
    binsTraded,
    isFetching: isFetchingBinsTraded,
    poolDistributionData,
    setBinTradedFilter
  } = useBinAnalytics({
    activeBinId,
    binTradedRadius: 100,
    distributionRadius,
    enabled: isSelected,
    lbPairAddress,
    tokenA,
    tokenB
  })

  if (isLoading) {
    return (
      <Center p={8}>
        <Spinner />
      </Center>
    )
  }

  if (!data) return null

  return (
    <Flex flexDir="column" gap={4}>
      <SimpleGrid columns={{ base: 2, md: 3 }} gap={4}>
        <AnalyticsStat
          title={t`Liquidity`}
          value={formattedNum(data.tvl, { usd: true })}
          change={data.tvlDelta}
        />
        <AnalyticsStat
          title={t`Volume (24H)`}
          value={formattedNum(data.volume24H, { usd: true })}
          change={data.volume24HDelta}
        />
        <AnalyticsStat
          title={t`Fees (24H)`}
          value={formattedNum(data.fees24H, { usd: true })}
          change={data.fees24HDelta}
        />
        <AnalyticsStat
          title={t`APR (7D)`}
          value={`${data.apr7D.toFixed(2)}%`}
        />
        {reserveX ? (
          <AnalyticsStat
            title={t`${currency0.symbol} Reserves`}
            value={`${formattedNum(
              formatUnits(reserveX, currency0.decimals)
            )} ${currency0.symbol}`}
          />
        ) : null}
        {reserveY ? (
          <AnalyticsStat
            title={t`${currency1.symbol} Reserves`}
            value={`${formattedNum(
              formatUnits(reserveY, currency1.decimals)
            )} ${currency1.symbol}`}
          />
        ) : null}
        {liquidityDepthTokenX ? (
          <AnalyticsStat
            title={t`+2% depth`}
            value={`${formattedNum(liquidityDepthTokenX)} ${currency0.symbol}`}
          />
        ) : null}
        {liquidityDepthTokenY ? (
          <AnalyticsStat
            title={t`-2% depth`}
            value={`${formattedNum(liquidityDepthTokenY)} ${currency1.symbol}`}
          />
        ) : null}
        {rewards ? (
          <AnalyticsStat
            title={t`Rewards APR (24H)`}
            value={`${formattedNum(rewards.apr * 100, { places: 2 })}%`}
          />
        ) : null}
      </SimpleGrid>
      <Box
        p={{ base: 4, md: 8 }}
        bg="bgCard"
        border="1px"
        borderColor="border"
        borderRadius="2xl"
      >
        <PoolInfoTable lbPairAddress={lbPairAddress} />
      </Box>
      {activeBinId ? (
        <Box
          p={{ base: 4, md: 8 }}
          bg="bgCard"
          border="1px"
          borderColor="border"
          borderRadius="2xl"
        >
          <LBPairDistributionChart
            title={t`Pool Distribution`}
            data={poolDistributionData}
            activeBinId={activeBinId}
            currency0={currency0}
            currency1={currency1}
            bottomRightElement={
              <ChartZoomButtons
                minValue={POOL_DISTRIBUTION_CHART_RADIUS.min}
                maxValue={POOL_DISTRIBUTION_CHART_RADIUS.max}
                value={distributionRadius}
                step={POOL_DISTRIBUTION_CHART_RADIUS.step}
                onChange={setDistributionRadius}
              />
            }
            isPriceRatioInversed={isPriceRatioInversed}
            showActiveBinMarker={true}
          />
        </Box>
      ) : null}
      {activeBinId && binsTraded.length > 0 ? (
        <Box
          p={{ base: 4, md: 8 }}
          bg="bgCard"
          border="1px"
          borderColor="border"
          borderRadius="2xl"
        >
          <BinsTradedChart
            isPriceRatioInversed={isPriceRatioInversed}
            activeBinId={activeBinId}
            binsTraded={binsTraded}
            currency0={currency0}
            currency1={currency1}
            setBinTradedFilter={setBinTradedFilter}
            isFetchingBinsTraded={isFetchingBinsTraded}
          />
        </Box>
      ) : null}
      <Box
        p={{ base: 4, md: 8 }}
        bg="bgCard"
        border="1px"
        borderColor="border"
        borderRadius="2xl"
      >
        <AnalyticsChart
          id="tvl"
          data={data.tvlDatas}
          header={
            <Heading size="md">
              <Trans>TVL (Total Locked Value)</Trans>
            </Heading>
          }
          h="220px"
          tooltipTitle="TVL"
          fill={graphPurple}
          stroke={graphPurple}
          showXAxis
          showYAxis
        />
      </Box>
      <Box
        p={{ base: 4, md: 8 }}
        bg="bgCard"
        border="1px"
        borderColor="border"
        borderRadius="2xl"
      >
        <AnalyticsChart
          id="volume"
          data={data.volumeDatas}
          header={
            <Heading size="md">
              <Trans>Volume</Trans>
            </Heading>
          }
          h="220px"
          tooltipTitle="Volume"
          fill={graphGreen}
          stroke={graphGreen}
          showXAxis
          showYAxis
        />
      </Box>
      {baseFee ? (
        <Box
          p={{ base: 4, md: 8 }}
          bg="bgCard"
          border="1px"
          borderColor="border"
          borderRadius="2xl"
        >
          <FeesBpsChart
            feesBpsHourDatas={data.feesBpsHourDatas}
            feesBpsDayDatas={data.feesBpsDayDatas}
            baseFee={baseFee}
          />
        </Box>
      ) : null}
      {swaps && (
        <Box
          p={{ base: 4, md: 8 }}
          bg="bgCard"
          border="1px"
          borderColor="border"
          borderRadius="2xl"
        >
          <PoolSwapsTable
            swaps={swaps}
            currency0={currency0}
            currency1={currency1}
          />
        </Box>
      )}
    </Flex>
  )
}

export default PoolDetailV2AnalyticsPanel
