import {
  Divider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import React from 'react'
import { GetBalanceData } from 'wagmi/query'

import VaultAddLiquidityPanel from './VaultAddLiquidityPanel'
import VaultRemoveLiquidityPanel from './VaultRemoveLiquidityPanel'

interface VaultAddRemoveLiquidityProps {
  vaultAddress: string
  balance0?: GetBalanceData
  balance1?: GetBalanceData
  currency0?: Currency
  currency1?: Currency
  depositDisabled?: boolean
  onAddLiquiditySuccess?: () => void
  onRemoveLiquiditySuccess?: () => void
}

const VaultAddRemoveLiquidity = (props: VaultAddRemoveLiquidityProps) => {
  return (
    <Tabs isFitted variant="soft-rounded" w="full">
      <TabList my={4} mx={{ base: 4, md: 8 }}>
        <Tab _selected={{ bg: 'joeLight.400', color: 'textPrimary' }}>
          <Trans>Add Liquidity</Trans>
        </Tab>
        <Tab
          data-cy="vault-remove-liquidity-tab"
          _selected={{ bg: 'joeLight.400', color: 'textPrimary' }}
        >
          <Trans>Remove Liquidity</Trans>
        </Tab>
      </TabList>
      <Divider />
      <TabPanels py={{ base: 4, md: 6 }} px={{ base: 4, md: 8 }}>
        <TabPanel p={0}>
          <VaultAddLiquidityPanel {...props} />
        </TabPanel>
        <TabPanel p={0}>
          <VaultRemoveLiquidityPanel {...props} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default VaultAddRemoveLiquidity
