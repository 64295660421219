import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Grid,
  Heading,
  HStack,
  Link,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'
import { LFJLogo } from 'theme/icons'

import FooterSection from './FooterSection'
import SocialNetworksSection from './SocialNetworksSection'

const LargeLink = ({
  href,
  subtitle,
  title
}: {
  href: string
  subtitle: string
  title: string
}) => {
  return (
    <Link
      p={{ base: 4, md: 6 }}
      bg="bgSecondary"
      w="full"
      borderRadius="2xl"
      href={href}
      isExternal
      _hover={{ bg: 'hover' }}
    >
      <HStack justify="space-around" w="full" h="full">
        <VStack w="full" align="flex-start" spacing={0}>
          <Heading fontSize="24px">{title}</Heading>
          <Text textColor="textSecondary" fontSize="sm">
            {subtitle}
          </Text>
        </VStack>

        <ExternalLinkIcon />
      </HStack>
    </Link>
  )
}

const Footer = () => {
  const year = new Date().getFullYear()

  return (
    <Box width="full" p={{ base: 4, md: 0 }}>
      <Box
        maxW={{ '2xl': '1600px', base: '1400px' }}
        margin="0 auto"
        pt={{ base: '48px', md: '96px' }}
        pb={{ base: '104px', md: '96px' }}
      >
        <Grid
          templateColumns={{ base: '1fr', md: 'minmax(0, 1fr) minmax(0, 3fr)' }}
          gap={2}
        >
          <SimpleGrid gap={2}>
            <LargeLink
              href={`https://support.${window.location.host}`}
              title="Support Centre"
              subtitle="Troubleshooting"
            />
            <LargeLink
              href="https://discord.gg/lfj"
              title="Official Discord"
              subtitle="Dedicated Support"
            />
          </SimpleGrid>
          <SimpleGrid
            columns={{ base: 2, md: 5 }}
            p={{ base: 4, md: 8 }}
            rowGap={4}
            columnGap={4}
            bg="bgSecondary"
            w="full"
            borderRadius="2xl"
          >
            <FooterSection
              title={t`Exchanges`}
              items={[
                {
                  href: 'https://www.binance.com/en/trade/JOE_USDT?layout=pro',
                  name: 'Binance'
                },
                {
                  href: 'https://crypto.com/price/joe',
                  name: 'Crypto.com'
                },
                {
                  href: 'https://www.gate.io/trade/JOE_USDT',
                  name: 'Gate.io'
                },
                {
                  href: 'https://www.mexc.com/exchange/JOE_USDT',
                  name: 'MEXC'
                },
                {
                  href: 'https://www.okx.com/markets/prices/joe-joe',
                  name: 'OKX'
                }
              ]}
            />
            <FooterSection
              title={t`JOE Token`}
              items={[
                {
                  href: 'https://www.coingecko.com/en/coins/joe',
                  name: 'Coingecko'
                },
                {
                  href: 'https://coinmarketcap.com/currencies/joe/',
                  name: 'CoinMarketCap'
                }
              ]}
            />
            <FooterSection
              title={t`About`}
              items={[
                {
                  href: `https://${window.location.host}/tos`,
                  name: 'Terms of Service'
                },
                {
                  href: `https://${window.location.host}/privacy`,
                  name: 'Privacy'
                }
              ]}
            />

            <FooterSection
              title={t`Business`}
              items={[
                {
                  href: 'https://github.com/traderjoe-xyz/joe-tokenlists',
                  name: t`Token Listing`
                }
              ]}
            />
            <FooterSection
              title={t`Developers`}
              items={[
                {
                  href: `https://support.${window.location.host}/en/articles/6881007-audits`,
                  name: 'Security'
                },
                {
                  href: `https://docs.${window.location.host}`,
                  name: 'Docs'
                },
                {
                  href: 'https://github.com/traderjoe-xyz',
                  name: 'GitHub'
                },
                {
                  href: 'https://discord.gg/lfj',
                  name: 'Report Bugs'
                }
              ]}
            />
          </SimpleGrid>
        </Grid>

        <Flex
          flexDir={{ base: 'column-reverse', md: 'row' }}
          justify="space-between"
          pt={{ base: 4, md: 8 }}
          gap={4}
        >
          <HStack spacing={4}>
            <LFJLogo />
            <Text fontSize="sm">© {year} Joemart Ltd</Text>
          </HStack>

          <SocialNetworksSection />
        </Flex>
      </Box>
    </Box>
  )
}

export default Footer
