import { Box, Flex, Heading, Show, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import PageHelmet from 'components/PageHelmet'
import useChainId from 'hooks/useChainId'
import useIsChainSunset from 'hooks/useIsChainSunset'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { LFJLogo } from 'theme/icons'
import { formattedNum } from 'utils/format'

import BoostPoolsTable from './BoostPools/BoostPoolsTable'
import JoepegSection from './Joepeg/JoepegSection'
import StakingSection from './StakingSection'
import StartTradingButton from './StartTradingButton'
import TokensRanking from './TokensRanking'

const Home = () => {
  const location = useLocation()
  const isChainSunset = useIsChainSunset()
  const chainId = useChainId()

  const volumeTraded = 130 * 1e9

  return (
    <>
      <PageHelmet url={location.pathname} />
      <Flex
        flexDir="column"
        gap={{ base: 10, md: 14 }}
        pt={{ base: 4, sm: '60px' }}
        maxW={{ '2xl': '1600px', base: '1416px' }}
        margin="0 auto"
        minH="calc(100vh - 60px)"
        pb={{ base: 8, md: 16 }}
        align="center"
      >
        <VStack
          align="center"
          w="full"
          spacing={4}
          px={{ base: 4, md: 0 }}
          pt={{ base: 0, md: 6 }}
        >
          <Show below="sm">
            <LFJLogo w="110px" />
          </Show>

          <Heading
            fontSize={{ base: '22px', md: '34px' }}
            fontFamily="Gaude"
            textTransform="uppercase"
            textAlign="center"
            pt={{ base: 4, sm: 0 }}
          >
            <Trans>Winners trade here</Trans>
          </Heading>

          <Heading fontSize={{ base: '22px', md: '26px' }} textAlign="center">
            ${formattedNum(volumeTraded)}{' '}
            <Box as="span" textColor="textSecondary">
              traded onchain
            </Box>
          </Heading>

          <StartTradingButton />
        </VStack>

        <VStack
          mt={{ base: 0, md: 4 }}
          w="full"
          spacing={{ base: 10, md: 20 }}
          align="flex-start"
          maxW="1200px"
        >
          {!isChainSunset ? <TokensRanking /> : null}
          {chainId === ChainId.AVALANCHE ? <BoostPoolsTable /> : null}
          <JoepegSection />
          <StakingSection />
        </VStack>
      </Flex>
    </>
  )
}

export default Home
