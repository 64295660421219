import {
  Box,
  Center,
  Flex,
  Heading,
  Hide,
  Spinner,
  Tab,
  TabList,
  Tabs,
  Text,
  useToken
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import useKeyPress from 'hooks/useKeyPress'
import React, { useMemo, useState } from 'react'
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { PoolQueryParam } from 'types/dexbarn'
import { BinVolumeData } from 'types/poolV2'
import { formattedNum } from 'utils/format'
import { inverseBinsData } from 'utils/poolV2'

import BinsTradedChartTooltip from './BinsTradedChartTooltip'

enum ChartRange {
  D7 = '7D',
  D14 = '14D',
  D30 = '30D',
  H24 = '24H'
}

const chartRanges = [
  ChartRange.H24,
  ChartRange.D7,
  ChartRange.D14,
  ChartRange.D30
]

interface BinsTradedChartProps {
  activeBinId: number
  binsTraded: BinVolumeData[]
  currency0: Currency
  currency1: Currency
  setBinTradedFilter: (filter: PoolQueryParam.FilterBy) => void
  isFetchingBinsTraded?: boolean
  isPriceRatioInversed?: boolean
}

const BinsTradedChart = ({
  activeBinId,
  binsTraded,
  currency0,
  currency1,
  isFetchingBinsTraded,
  isPriceRatioInversed = false,
  setBinTradedFilter
}: BinsTradedChartProps) => {
  const [
    graphPurpleDark,
    graphPurpleLight,
    graphGreenDark,
    graphGreenLight,
    textSecondary
  ] = useToken('colors', [
    'graphPurpleDark',
    'graphPurpleLight',
    'graphGreenDark',
    'graphGreenLight',
    'textSecondary'
  ])

  const [chartRange, setChartRange] = useState<ChartRange>(ChartRange.H24)
  const [priceDecimals, setPriceDecimals] = useState(10)
  useKeyPress({
    onUp: () => setPriceDecimals((prev) => (prev === 18 ? 10 : 18)),
    targetKey: 'g'
  })

  const data = useMemo(
    () => (isPriceRatioInversed ? inverseBinsData(binsTraded) : binsTraded),
    [binsTraded, isPriceRatioInversed]
  )

  const [focusBar, setFocusBar] = useState<number | undefined>()
  const dataKey = 'volumeUsd'

  const yAxisDomain = useMemo(() => {
    const yAxisMaxValue = binsTraded
      .map((data) => data[dataKey])
      .reduce((a, b) => Math.max(a, b), -Infinity)
    return [0, yAxisMaxValue]
  }, [binsTraded])

  const handleFilterChange = (index: number) => {
    switch (index) {
      case 0:
        setBinTradedFilter('1d')
        break
      case 1:
        setBinTradedFilter('7d')
        break
      case 2:
        setBinTradedFilter('14d')
        break
      case 3:
        setBinTradedFilter('30d')
        break
    }
  }

  return (
    <Box pos="relative">
      <Flex justify="space-between" align="center" mb={{ base: 0, sm: 8 }}>
        <Heading size="md" mb={2}>
          Bins Traded
        </Heading>
        <Hide below="md">
          <Tabs
            variant="soft-rounded"
            colorScheme="accent"
            index={chartRanges.indexOf(chartRange)}
            onChange={(index) => {
              setChartRange(chartRanges[index])
              handleFilterChange(index)
            }}
          >
            <TabList>
              <Tab>24H</Tab>
              <Tab>7D</Tab>
              <Tab>14D</Tab>
              <Tab>30D</Tab>
            </TabList>
          </Tabs>
        </Hide>
      </Flex>

      <Box h="160px">
        {isFetchingBinsTraded ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <ResponsiveContainer width="99%">
            <BarChart
              data={data}
              onMouseMove={(state) => {
                setFocusBar(
                  state.isTooltipActive ? state.activeTooltipIndex : undefined
                )
              }}
              onMouseLeave={() => {
                setFocusBar(undefined)
              }}
              margin={{ bottom: 16 }}
            >
              <Tooltip
                wrapperStyle={{ outline: 'none' }}
                cursor={{ fill: 'transparent' }}
                content={
                  <BinsTradedChartTooltip
                    dataKey={dataKey}
                    symbolX={currency0.symbol ?? ''}
                    symbolY={currency1.symbol ?? ''}
                  />
                }
              />
              <YAxis
                width={80}
                axisLine={false}
                tickLine={false}
                tickSize={0}
                tickMargin={20}
                domain={yAxisDomain}
                fontSize="12px"
                tick={{ fill: textSecondary }}
                tickFormatter={(val) =>
                  formattedNum(val, {
                    allowSmallDecimals: true,
                    places: priceDecimals,
                    usd: true
                  })
                }
              />
              <XAxis
                xAxisId="0"
                tickSize={0}
                minTickGap={16}
                axisLine={false}
                dataKey="priceXY"
                tickFormatter={(val) =>
                  formattedNum(val, {
                    allowDecimalsOver1000: true,
                    allowSmallDecimals: true,
                    places: priceDecimals
                  })
                }
                fontSize="12px"
                fontWeight="semibold"
                tick={{ fill: textSecondary }}
                tickMargin={12}
              />
              <defs>
                <linearGradient id="activeId" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="0%"
                    stopColor={graphPurpleDark}
                    stopOpacity={1}
                  />
                  <stop
                    offset="100%"
                    stopColor={graphPurpleDark}
                    stopOpacity={0.01}
                  />
                </linearGradient>
                <linearGradient id="nonActiveId" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="0%"
                    stopColor={graphGreenDark}
                    stopOpacity={1}
                  />
                  <stop
                    offset="100%"
                    stopColor={graphGreenDark}
                    stopOpacity={0.01}
                  />
                </linearGradient>
                <linearGradient
                  opacity={0.5}
                  id="activeIdFocus"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset="0%"
                    stopColor={graphGreenLight}
                    stopOpacity={1}
                  />
                  <stop
                    offset="100%"
                    stopColor={graphGreenLight}
                    stopOpacity={0}
                  />
                </linearGradient>
                <linearGradient
                  opacity={0.5}
                  id="nonActiveIdFocus"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset="0%"
                    stopColor={graphPurpleLight}
                    stopOpacity={1}
                  />
                  <stop
                    offset="100%"
                    stopColor={graphPurpleLight}
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>
              <Bar dataKey={dataKey} radius={2}>
                {binsTraded.map((data, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      focusBar === index && data.binId === activeBinId
                        ? 'url(#activeIdFocus)'
                        : focusBar === index && data.binId !== activeBinId
                          ? 'url(#nonActiveIdFocus)'
                          : data.binId === activeBinId
                            ? 'url(#activeId)'
                            : 'url(#nonActiveId)'
                    }
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}

        <Center mt={-2}>
          {!isFetchingBinsTraded ? (
            <Text fontSize="sm" fontWeight="semibold">
              <Trans>Bin Price</Trans>
            </Text>
          ) : null}
        </Center>
      </Box>
    </Box>
  )
}

export default BinsTradedChart
