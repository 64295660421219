import { ChainId } from '@traderjoe-xyz/sdk-core'

export const SJOE_SUBGRAPH_ID: Partial<{ [chainId in ChainId]: string }> = {
  [ChainId.ARBITRUM_ONE]: 'AG1j8CbDe38nT25HAKqppSZXdCcWMBUYAeXNRdBE54Qa',
  [ChainId.AVALANCHE]: '63h9acD1gvr3VobXbqK7QmugK5r4FFHRnzMqKP4nAhd9'
}

export const FEE_COLLECTOR_SUBGRAPH_ID: Partial<{
  [chainId in ChainId]: string
}> = {
  [ChainId.AVALANCHE]: '22nJbR5KwAPVrxsPtKGVfopNAtrcDP7JxvnbCn2DXPgK',
  [ChainId.ARBITRUM_ONE]: 'AuX5GL2oSPVcgHUbBow5SU3yoxWFNFdmGLvEX9nb1gUb'
}
