import { Box, Flex, Td, Tr } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import useChainId from 'hooks/useChainId'
import useIsChainSunset from 'hooks/useIsChainSunset'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { LBPool, Pool } from 'types/pool'
import { formattedNum } from 'utils/format'
import { getPoolDetailUrl } from 'utils/poolUrl'

import LbRewardsPopover from './LbRewardsPopover'
import PoolTableNameColumn from './PoolTableNameColumn'

interface PoolTableRow {
  pool: Pool | LBPool
}

const PoolTableRow = ({ pool }: PoolTableRow) => {
  const chainId = useChainId()
  const isChainSunset = useIsChainSunset() && 'lbBinStep' in pool
  const navigate = useNavigate()

  const epochRewards = 'rewards' in pool ? pool.rewards : undefined

  const lbRewards = 'lbRewards' in pool ? pool.lbRewards : undefined
  const totalLbRewardsApr = lbRewards
    ? lbRewards.reduce((acc, r) => acc + (r.rewardsApr || 0), 0)
    : undefined

  const liveRewards = epochRewards?.find((r) => r.epoch.status === 'ongoing')
  const totalApr = liveRewards
    ? pool.apr + liveRewards.apr
    : lbRewards && lbRewards.length > 0 && totalLbRewardsApr
      ? totalLbRewardsApr + pool.apr
      : pool.apr

  const feesTooltipLabel = (
    <Box>
      <Trans>
        Fees APR: {`${(pool.apr * 100).toFixed(2)}%`} <br />
        Rewards APR:{' '}
        {liveRewards
          ? `${(liveRewards.apr * 100).toFixed(2)}%`
          : totalLbRewardsApr
            ? `${(totalLbRewardsApr * 100).toFixed(2)}%`
            : t`N/A`}
      </Trans>
    </Box>
  )

  return (
    <Tr
      data-cy={`pool-row-${pool.pairAddress}`}
      key={pool.pairAddress}
      transform="scale(1)"
      cursor="pointer"
      role="group"
      aria-label={`${pool.tokenX.symbol}-${pool.tokenY.symbol} liquidity pool`}
      onClick={() => navigate(getPoolDetailUrl(pool, chainId))}
    >
      <Td>
        <PoolTableNameColumn
          token0={pool.tokenX}
          token1={pool.tokenY}
          fees={`${pool.feePct}%`}
          isRewarded={!!epochRewards}
          isPoolMigrated={pool.isMigrated}
          rewards={epochRewards?.slice(0, 2)}
          extraTag={
            lbRewards && lbRewards.length > 0 ? (
              <LbRewardsPopover rewards={lbRewards} />
            ) : undefined
          }
          points={pool.points}
        />
      </Td>
      <Td textAlign="right">
        {isChainSunset ? '--' : formattedNum(pool.volumeUsd, { usd: true })}
      </Td>
      <Td textAlign="right">
        {isChainSunset ? '--' : formattedNum(pool.liquidityUsd, { usd: true })}
      </Td>
      <Td textAlign="right">
        {isChainSunset
          ? '--'
          : 'liquidityUsdDepthPlus' in pool
            ? formattedNum(pool.liquidityUsdDepthPlus, { usd: true })
            : '--'}
      </Td>
      <Td textAlign="right">
        {isChainSunset
          ? '--'
          : 'liquidityUsdDepthMinus' in pool
            ? formattedNum(pool.liquidityUsdDepthMinus, { usd: true })
            : '--'}
      </Td>
      <Td textAlign="right">
        {isChainSunset ? '--' : formattedNum(pool.feesUsd, { usd: true })}
      </Td>
      <Td textAlign="right">
        <Flex alignItems="center" justifyContent="end">
          {epochRewards || (lbRewards && lbRewards.length > 0) ? (
            <Box textDecoration="dotted underline">
              <TouchFriendlyTooltip label={feesTooltipLabel}>
                {formattedNum(totalApr * 100, { places: 2 })}%
              </TouchFriendlyTooltip>
            </Box>
          ) : (
            <Box>
              {isChainSunset
                ? '--'
                : formattedNum(totalApr * 100, { places: 2 })}
              %
            </Box>
          )}
        </Flex>
      </Td>
    </Tr>
  )
}

export default PoolTableRow
