import { Checkbox, Link, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { useAreTermsAccepted } from 'state/settings/hooks'

const Disclaimer = () => {
  const { areTermsAccepted, updateAreTermsAccepted } = useAreTermsAccepted()

  return (
    <VStack align="flex-start">
      <Text fontSize="sm" align="left">
        <Trans>
          By connecting your wallet, you agree to the{' '}
          <Link
            isExternal
            href={`https://${window.location.host}/tos`}
            color="accent.500"
          >
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link
            isExternal
            href={`https://${window.location.host}/privacy`}
            color="accent.500"
          >
            Privacy Policy
          </Link>
          .
        </Trans>
      </Text>
      <Checkbox
        colorScheme="accent"
        isChecked={areTermsAccepted}
        onChange={(e) => {
          updateAreTermsAccepted(e.target.checked)
        }}
      >
        <Text fontSize="sm">
          <Trans>I read and accept</Trans>
        </Text>
      </Checkbox>
    </VStack>
  )
}

export default Disclaimer
