import {
  Icon,
  IconProps,
  Image,
  ImageProps,
  useColorMode
} from '@chakra-ui/react'
import { ReactComponent as Achievement } from 'assets/achievement.svg'
import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg'
import { ReactComponent as ArrowLeftRight } from 'assets/arrow-left-right.svg'
import { ReactComponent as ArrowUpDown } from 'assets/arrow-up-down.svg'
import { ReactComponent as Book } from 'assets/book.svg'
import { ReactComponent as Bridge } from 'assets/bridge.svg'
import { ReactComponent as CoinMarketCap } from 'assets/coinmarketcap.svg'
import { ReactComponent as Copy } from 'assets/copy.svg'
import { ReactComponent as Disconnect } from 'assets/disconnect.svg'
import { ReactComponent as Discord } from 'assets/discord.svg'
import { ReactComponent as FarmTractor } from 'assets/farm-tractor.svg'
import { ReactComponent as GKey } from 'assets/g-key.svg'
import { ReactComponent as Github } from 'assets/github.svg'
import { ReactComponent as Hamburger } from 'assets/hamburger.svg'
import { ReactComponent as Keyboard } from 'assets/keyboard.svg'
import LFGLogo from 'assets/lfg_logo_1x.webp'
import LFGLogo2x from 'assets/lfg_logo_2x.webp'
import LFGLogo3x from 'assets/lfg_logo_3x.webp'
import LFGLogoDark from 'assets/lfg_logo_dark_1x.webp'
import LFGLogoDark2x from 'assets/lfg_logo_dark_2x.webp'
import LFGLogoDark3x from 'assets/lfg_logo_dark_3x.webp'
import { ReactComponent as Link } from 'assets/link.svg'
import { ReactComponent as Moon } from 'assets/moon.svg'
import { ReactComponent as MyActivity } from 'assets/my-activity.svg'
import { ReactComponent as MyNft } from 'assets/my-nft.svg'
import { ReactComponent as MyPool } from 'assets/my-pool.svg'
import { ReactComponent as MySettings } from 'assets/my-settings.svg'
import { ReactComponent as PieChart } from 'assets/pie-chart.svg'
import { ReactComponent as PlaceOrder } from 'assets/place-order.svg'
import { ReactComponent as Pool } from 'assets/pool-icon.svg'
import { ReactComponent as Refresh } from 'assets/refresh.svg'
import { ReactComponent as Robot } from 'assets/robot.svg'
import { ReactComponent as Search } from 'assets/search.svg'
import { ReactComponent as Settings } from 'assets/settings.svg'
import { ReactComponent as Star } from 'assets/star.svg'
import { ReactComponent as Substack } from 'assets/substack.svg'
import { ReactComponent as Sun } from 'assets/sun.svg'
import { ReactComponent as TKey } from 'assets/t-key.svg'
import { ReactComponent as Telegram } from 'assets/telegram.svg'
import { ReactComponent as ThreeDots } from 'assets/three-dots.svg'
import { ReactComponent as Trade } from 'assets/trade.svg'
import { ReactComponent as UKey } from 'assets/u-key.svg'
import { ReactComponent as Wallet } from 'assets/wallet.svg'
import { ReactComponent as Warning } from 'assets/warning.svg'
import { ReactComponent as X } from 'assets/x.svg'
import { ReactComponent as Zap } from 'assets/zap.svg'
import React from 'react'

const AchievementIcon = (props: IconProps) => (
  <Icon as={Achievement} fill="textPrimary" {...props} />
)

const ArrowUpDownIcon = (props: IconProps) => (
  <Icon as={ArrowUpDown} fill="textPrimary" {...props} />
)

const ArrowLeftRightIcon = (props: IconProps) => (
  <Icon as={ArrowLeftRight} fill="textPrimary" {...props} />
)

const SettingsIcon = (props: IconProps) => (
  <Icon as={Settings} fill="textPrimary" {...props} />
)

const MoonIcon = (props: IconProps) => (
  <Icon as={Moon} stroke="textPrimary" {...props} />
)

const SunIcon = (props: IconProps) => (
  <Icon as={Sun} fill="textPrimary" {...props} />
)

const ThreeDotsIcon = (props: IconProps) => (
  <Icon as={ThreeDots} fill="textPrimary" {...props} />
)

const DiscordIcon = (props: IconProps) => (
  <Icon as={Discord} fill="textPrimary" {...props} />
)

const XIcon = (props: IconProps) => (
  <Icon as={X} fill="textPrimary" {...props} />
)

const GithubIcon = (props: IconProps) => (
  <Icon as={Github} fill="textPrimary" {...props} />
)

const SubstackIcon = (props: IconProps) => (
  <Icon as={Substack} fill="textPrimary" {...props} />
)

const CoinMarketCapIcon = (props: IconProps) => (
  <Icon as={CoinMarketCap} fill="textPrimary" {...props} />
)

const WalletIcon = (props: IconProps) => (
  <Icon as={Wallet} fill="textPrimary" {...props} />
)

const StarIcon = (props: IconProps) => (
  <Icon as={Star} stroke="textPrimary" {...props} />
)

const TradeIcon = (props: IconProps) => (
  <Icon as={Trade} fill="textPrimary" {...props} />
)

const PoolIcon = (props: IconProps) => (
  <Icon as={Pool} fill="textPrimary" {...props} />
)

const PlaceOrderIcon = (props: IconProps) => (
  <Icon as={PlaceOrder} fill="textPrimary" {...props} />
)

const BookIcon = (props: IconProps) => (
  <Icon as={Book} fill="textPrimary" {...props} />
)

const PieChartIcon = (props: IconProps) => (
  <Icon as={PieChart} fill="textPrimary" {...props} />
)

const RefreshIcon = (props: IconProps) => (
  <Icon as={Refresh} fill="textPrimary" {...props} />
)

const HamburgerIcon = (props: IconProps) => (
  <Icon as={Hamburger} fill="textPrimary" {...props} />
)

const SearchIcon = (props: IconProps) => (
  <Icon as={Search} fill="textPrimary" {...props} />
)

const KeyboardIcon = (props: IconProps) => (
  <Icon as={Keyboard} fill="textPrimary" {...props} />
)

const GKeyIcon = (props: IconProps) => (
  <Icon as={GKey} fill="textPrimary" {...props} />
)

const TKeyIcon = (props: IconProps) => (
  <Icon as={TKey} fill="textPrimary" {...props} />
)

const UKeyIcon = (props: IconProps) => (
  <Icon as={UKey} fill="textPrimary" {...props} />
)

const RobotIcon = (props: IconProps) => (
  <Icon as={Robot} fill="textPrimary" {...props} />
)

const FarmTractorIcon = (props: IconProps) => (
  <Icon as={FarmTractor} fill="textPrimary" {...props} />
)

const CopyIcon = (props: IconProps) => (
  <Icon as={Copy} fill="textPrimary" {...props} />
)

const LinkIcon = (props: IconProps) => (
  <Icon as={Link} fill="textPrimary" {...props} />
)

const MyPoolIcon = (props: IconProps) => (
  <Icon as={MyPool} fill="textPrimary" {...props} />
)

const MyActivityIcon = (props: IconProps) => (
  <Icon as={MyActivity} fill="textPrimary" {...props} />
)

const MyNftIcon = (props: IconProps) => (
  <Icon as={MyNft} fill="textPrimary" {...props} />
)

const MySettingsIcon = (props: IconProps) => (
  <Icon as={MySettings} fill="textPrimary" {...props} />
)

const ArrowLeftIcon = (props: IconProps) => (
  <Icon as={ArrowLeft} fill="textPrimary" {...props} />
)

const WarningIcon = (props: IconProps) => (
  <Icon as={Warning} fill="textPrimary" {...props} />
)

const DisconnectIcon = (props: IconProps) => (
  <Icon as={Disconnect} fill="red.500" {...props} />
)

const TelegramIcon = (props: IconProps) => (
  <Icon as={Telegram} fill="textPrimary" {...props} />
)

const ZapIcon = (props: IconProps) => (
  <Icon as={Zap} fill="textPrimary" {...props} />
)

const BridgeIcon = (props: IconProps) => (
  <Icon as={Bridge} fill="textPrimary" {...props} />
)

const LFJLogo = (props: ImageProps) => {
  const { colorMode } = useColorMode()

  return (
    <Image
      objectFit="contain"
      fallbackSrc={colorMode === 'light' ? LFGLogoDark3x : LFGLogo3x}
      srcSet={
        colorMode === 'light'
          ? `${LFGLogo} 1x, ${LFGLogo2x} 2x, ${LFGLogo3x} 3x`
          : `${LFGLogoDark} 1x, ${LFGLogoDark2x} 2x, ${LFGLogoDark3x} 3x`
      }
      w="90px"
      {...props}
    />
  )
}

export {
  AchievementIcon,
  ArrowLeftIcon,
  ArrowLeftRightIcon,
  ArrowUpDownIcon,
  BookIcon,
  BridgeIcon,
  CoinMarketCapIcon,
  CopyIcon,
  DisconnectIcon,
  DiscordIcon,
  FarmTractorIcon,
  GithubIcon,
  GKeyIcon,
  HamburgerIcon,
  KeyboardIcon,
  LFJLogo,
  LinkIcon,
  MoonIcon,
  MyActivityIcon,
  MyNftIcon,
  MyPoolIcon,
  MySettingsIcon,
  PieChartIcon,
  PlaceOrderIcon,
  PoolIcon,
  RefreshIcon,
  RobotIcon,
  SearchIcon,
  SettingsIcon,
  StarIcon,
  SubstackIcon,
  SunIcon,
  TelegramIcon,
  ThreeDotsIcon,
  TKeyIcon,
  TradeIcon,
  UKeyIcon,
  WalletIcon,
  WarningIcon,
  XIcon,
  ZapIcon
}
