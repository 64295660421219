import { LinkBox, Td, Tr } from '@chakra-ui/react'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { VaultWithUserPosition } from 'types/vault'
import { getChainSlug } from 'utils/chains'
import { formattedNum } from 'utils/format'
import {
  getFarmRowFormattedValues,
  getVaultRowFormattedValues
} from 'utils/vault'

import PoolTableNameColumn from '../../PoolTableNameColumn'

interface VaultRowProps {
  vault: VaultWithUserPosition
}

const VaultRow = ({ vault }: VaultRowProps) => {
  const chainId = useChainId()
  const navigate = useNavigate()
  const {
    apt1dPriceChange,
    apt1dPriceChangeColor,
    aptPrice,
    feesUsd1D,
    liquidity,
    myFunds
  } = getVaultRowFormattedValues(vault)
  const { farmApr1D, myFarmDepositUSD } = getFarmRowFormattedValues(vault)

  const totalDeposit = myFunds + myFarmDepositUSD

  const fmtTotalFundsDeposit =
    totalDeposit > 0
      ? formattedNum(totalDeposit, {
          usd: true
        })
      : '--'

  return (
    <LinkBox
      as={Tr}
      transform="scale(1)"
      cursor="pointer"
      role="group"
      data-cy={`vault-row-${vault.id}`}
      onClick={() => navigate(`/${getChainSlug(chainId)}/vault/${vault.id}`)}
    >
      <Td>
        <PoolTableNameColumn
          token0={vault.tokenX}
          token1={vault.tokenY}
          fees={`${vault.lbPair.baseFeePct}%`}
          isRewarded={vault.isRewarded}
        />
      </Td>
      <Td textAlign="right">{liquidity}</Td>
      <Td textAlign="right">{fmtTotalFundsDeposit}</Td>
      <Td textAlign="right">{aptPrice}</Td>
      <Td textAlign="right" textColor={apt1dPriceChangeColor}>
        {apt1dPriceChange}
      </Td>
      <Td textAlign="right">{feesUsd1D}</Td>
      <Td textAlign="right">{farmApr1D}</Td>
    </LinkBox>
  )
}

export default VaultRow
