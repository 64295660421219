import { Point } from 'types/pool'
import { getAddress } from 'viem'

export const getPoolPoints = (poolAddress: string): Point[] => {
  const stablejackAddress = '0x934cf521743903D27e388d7E8517c636f3Cc4D54'

  const boostPools = [
    '0x2823299af89285fF1a1abF58DB37cE57006FEf5D',
    '0xEA7309636E7025Fda0Ee2282733Ea248c3898495',
    '0x2ffB5DDc1B597A6C0AB7BDD1455BC487f902D731',
    '0x9e5C4aDe56A7e85e18B1aC8030Df171107395b99',
    '0x9CA6D621A3b1b321BFd32B5EBaF5ad9fcF8C9e31',
    '0x934cf521743903D27e388d7E8517c636f3Cc4D54',
    '0x8db6684838dBdA65952ae4bC576503f6eCC03864',
    '0x70898cAa8ea8E9B2BA2e68708E2B761404dd07d6',
    '0x883eA72c2A46F7AcB3820855344C43666c6cc5c0',
    '0x95bA6bde7fa47982Acd384a11d59BB1C7E78706f',
    '0x2039b96D7c71Ad87c7C399e7D2177f270849dCc0',
    '0xcEC377285AbF370FDf872625D2742252656d631a',
    '0x8573F98175D816d520248B5fACF40D309B1c9ceE',
    '0x632349B44Af299Ab83cB9F21F65c218122fD4772',
    '0x856b38Bf1e2E367F747DD4d3951DDA8a35F1bF60',
    '0xe92C7661E51121F167D7b36Ed07D297E3792A95f',
    '0x55C211BBE9f63059a4A5A5E0c558c7e410412d98',
    '0xF9Ab5dD8f239E2916f54e8b1de171aA9C04aa136',
    '0x805aF256903bfe271255242c3eeC2a6DC6eb319d',
    '0x709A7818770470f2abc6532b28EF26151f3Cfa6c',
    '0x015B96160687614E76BF038d507DFf2e683C2AC4'
  ]

  const points: Point[] = []

  if (getAddress(poolAddress) === getAddress(stablejackAddress)) {
    points.push('stablejack')
  }

  if (boostPools.includes(getAddress(poolAddress))) {
    points.push('boost')
  }

  return points
}
