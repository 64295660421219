import {
  Box,
  Flex,
  FlexProps,
  Heading,
  Hide,
  HStack,
  Tab,
  TabList,
  Tabs,
  Tag,
  Text
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import BackButton from 'components/BackButton'
import ContractLink from 'components/ContractLink'
import CurrencyLogo from 'components/CurrencyLogo'
import HotkeyTooltipButton from 'components/HotkeyTooltipButton'
import PointsBadge from 'components/PointsBadge'
import SlippageSettingsPicker, {
  SlippageSettingsPickerType
} from 'components/SlippageSettingsPicker'
import useChainId from 'hooks/useChainId'
import useKeyPress from 'hooks/useKeyPress'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Point } from 'types/pool'
import { getChainSlug } from 'utils/chains'
import { formattedNum } from 'utils/format'

interface PoolDetailHeaderProps {
  activeBinPrice?: string
  address?: string
  inversePriceRatios?: boolean
  isRewarded?: boolean
  onInversePriceRatiosClick?: () => void
  points?: Point[]
  settingsBottom?: number
  showHotkeyTooltip?: boolean
  slippageSettingsPickerType?: SlippageSettingsPickerType
  tag?: string
  token0?: { address?: string; symbol?: string }
  token1?: { address?: string; symbol?: string }
}

const PoolDetailHeader = ({
  activeBinPrice,
  address,
  inversePriceRatios,
  isRewarded,
  onInversePriceRatiosClick,
  points,
  settingsBottom = 0,
  showHotkeyTooltip,
  slippageSettingsPickerType,
  tag,
  token0,
  token1,
  ...props
}: PoolDetailHeaderProps & FlexProps) => {
  const chainId = useChainId()
  const navigate = useNavigate()

  const [priceDecimals, setPriceDecimals] = useState(10)
  useKeyPress({
    onUp: () => setPriceDecimals((prev) => (prev === 18 ? 10 : 18)),
    targetKey: 'g'
  })

  return (
    <Flex
      maxW={{ '2xl': '1600px', base: '1400px' }}
      margin="0 auto"
      flexDir="column"
      align="flex-start"
      gap={2}
      pos="relative"
      h={{ base: 'fit-content', md: '150px' }}
      {...props}
    >
      <BackButton
        title={t`Back to pools list`}
        ml={-3}
        minH="24px"
        onClick={() => navigate(`/${getChainSlug(chainId)}/pool`)}
      />
      <Flex
        zIndex={1}
        gap={2}
        flexDir={{ base: 'column', md: 'row' }}
        align={{ base: 'flex-start', md: 'center' }}
      >
        <HStack>
          <HStack>
            <CurrencyLogo
              address={token0?.address}
              symbol={token0?.symbol}
              boxSize={7}
            />
            <Heading size="lg">{token0?.symbol ?? token0?.address}</Heading>
          </HStack>
          <Heading size="lg">/</Heading>
          <HStack>
            <CurrencyLogo
              address={token1?.address}
              symbol={token1?.symbol ?? token1?.address}
              boxSize={7}
            />
            <Heading size="lg">{token1?.symbol ?? token1?.address}</Heading>
          </HStack>
        </HStack>
        <Flex flexWrap="wrap" gap={2} pl={{ base: 0, md: 4 }}>
          {tag ? <Tag variant="outline">{tag}</Tag> : null}
          {isRewarded ? (
            <Tag variant="outline">
              <Trans>Rewards</Trans>
            </Tag>
          ) : null}
          <Tag variant="outline">
            <ContractLink color="textPrimary" address={address} />
          </Tag>
          {points && points.length > 0 ? <PointsBadge points={points} /> : null}
        </Flex>
      </Flex>
      <Flex
        pos={{ base: 'relative', md: 'absolute' }}
        bottom={{ base: 0, md: settingsBottom }}
        mt={{ base: 2, md: 0 }}
        alignSelf={{ base: 'flex-start', md: 'flex-end' }}
        justifyContent={{ base: 'space-between', md: 'flex-start' }}
        alignItems="center"
        zIndex={4}
        gap={{ base: 2, md: 4 }}
        w={{ base: 'full', md: 'auto' }}
        pt={{ base: 0, md: 4 }}
      >
        {activeBinPrice ? (
          <Box>
            <Text fontSize="sm" textColor="textSecondary">
              <Trans>Current Price:</Trans>
            </Text>
            <Text fontWeight="semibold">{`1 ${
              inversePriceRatios ? token1?.symbol : token0?.symbol
            } = ${formattedNum(activeBinPrice, { places: priceDecimals })} ${
              inversePriceRatios ? token0?.symbol : token1?.symbol
            }`}</Text>
          </Box>
        ) : null}
        <HStack spacing={{ base: 2, md: 4 }}>
          {onInversePriceRatiosClick ? (
            <Tabs
              isManual
              variant="soft-rounded"
              index={inversePriceRatios ? 1 : 0}
              onChange={onInversePriceRatiosClick}
            >
              <TabList bg="white" _dark={{ bg: 'joeDark.500' }}>
                <Tab
                  _selected={{
                    bg: 'bgTertiary',
                    textColor: 'textPrimary'
                  }}
                >
                  {token0?.symbol}
                </Tab>
                <Tab
                  _selected={{
                    bg: 'bgTertiary',
                    textColor: 'textPrimary'
                  }}
                >
                  {token1?.symbol}
                </Tab>
              </TabList>
            </Tabs>
          ) : null}
          {slippageSettingsPickerType ? (
            <SlippageSettingsPicker
              type={slippageSettingsPickerType}
              iconButtonProps={{
                'aria-label': 'open settings',
                bg: 'white'
              }}
            />
          ) : null}
          {showHotkeyTooltip ? (
            <Hide below="md">
              <HotkeyTooltipButton />
            </Hide>
          ) : null}
        </HStack>
      </Flex>
    </Flex>
  )
}

export default PoolDetailHeader
