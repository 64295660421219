import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Heading,
  Hide,
  HStack,
  Image,
  Link,
  ListItem,
  Show,
  SimpleGrid,
  Text,
  UnorderedList,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import StakeIllustrationSrc from 'assets/stake.png'
import PageHelmet from 'components/PageHelmet'
import { STAKE_HELMET_DESCRIPTION, STAKE_HELMET_TITLE } from 'constants/stake'
import useGetStakingInfo from 'hooks/stake/useGetStakingInfo'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { BridgeIcon } from 'theme/icons'

import StakeAnalyticsGrid from './StakeAnalyticsGrid'
import StakeCard from './StakeCard'

const Stake = () => {
  const location = useLocation()

  const {
    arbitrumApr30D,
    arbitrumPayoutDayDatas,
    arbitrumStakingData,
    avalancheApr30D,
    avalanchePayoutDayDatas,
    avalancheStakingData,
    isLoading,
    totalFees1D,
    totalFees7D,
    totalFees30D,
    totalStaked
  } = useGetStakingInfo()

  return (
    <Box
      pt={4}
      pb={12}
      px={{ base: 4, md: 0 }}
      maxW={{ '2xl': '1600px', base: '1400px' }}
      margin="0 auto"
    >
      <PageHelmet
        description={STAKE_HELMET_DESCRIPTION}
        title={STAKE_HELMET_TITLE}
        url={location.pathname}
      />
      <Flex flexDir={{ base: 'column', md: 'row' }} justify="space-between">
        <Box maxW="612px">
          <HStack justify="space-between">
            <VStack align="flex-start" spacing={1}>
              <Heading>
                <Trans>Stake</Trans>
              </Heading>
              <Text color="textSecondary" fontSize="sm">
                <Trans>Stake your JOE tokens and earn more.</Trans>
              </Text>
            </VStack>

            <Show below="md">
              <Image src={StakeIllustrationSrc} maxW="70px" />
            </Show>
          </HStack>

          <VStack pt={{ base: 4, md: 12 }} align="flex-start">
            <Heading size="md">
              <Trans>How it works?</Trans>
            </Heading>
            <UnorderedList fontSize="sm" textColor="textSecondary" spacing={2}>
              <ListItem>
                <Trans>
                  Stake JOE tokens on your chosen chain to become eligible to
                  earn a share of protocol fees, collected from swaps on the
                  DEX.
                </Trans>
              </ListItem>
              <ListItem>
                <Trans>
                  Rewards are distributed in USD stablecoins every few days with
                  your share proportional to the amount of JOE you have staked
                  compared to the total amount of JOE staked.
                </Trans>
              </ListItem>
              <ListItem>
                <Trans>
                  No lockups, entry or exit fees, and you can top up your staked
                  position anytime.
                </Trans>
              </ListItem>
            </UnorderedList>
          </VStack>
        </Box>

        <Hide below="md">
          <Image src={StakeIllustrationSrc} maxW="328px" />
        </Hide>
      </Flex>

      <Box pt={8}>
        <StakeAnalyticsGrid
          totalStaked={totalStaked}
          fees1D={totalFees1D}
          fees7D={totalFees7D}
          fees30D={totalFees30D}
          isLoading={isLoading}
        />
      </Box>

      <SimpleGrid
        pt={{ base: 8, md: '96px' }}
        columns={{ base: 1, md: 2 }}
        gap={{ base: 4, md: 6 }}
      >
        <StakeCard
          totalStaked={avalancheStakingData?.totalStaked}
          fees30d={avalanchePayoutDayDatas?.feesUsd30D}
          apr30d={avalancheApr30D}
          chainId={ChainId.AVALANCHE}
          isLoading={isLoading}
        />
        <StakeCard
          totalStaked={arbitrumStakingData?.totalStaked}
          fees30d={arbitrumPayoutDayDatas?.feesUsd30D}
          apr30d={arbitrumApr30D}
          chainId={ChainId.ARBITRUM_ONE}
          isLoading={isLoading}
        />
      </SimpleGrid>

      <VStack
        pt={{ base: 8, md: '96px' }}
        spacing={{ base: 4, md: 6 }}
        align={{ base: 'flex-start', md: 'center' }}
      >
        <Text fontSize="sm">
          Send your JOE tokens quickly and securely across chains. You only pay
          gas, no fees are charged for cross chain transaction
        </Text>
        <Link
          isExternal
          href={`https://bridge.${window.location.host}/`}
          w={{ base: 'full', md: 'auto' }}
        >
          <Button
            w={{ base: 'full', md: 'auto' }}
            variant="outline"
            leftIcon={<BridgeIcon fill="textPrimary" boxSize={5} />}
          >
            Joe Bridge
          </Button>
        </Link>
      </VStack>

      <VStack
        pt={{ base: 8, md: '96px' }}
        spacing={{ base: 4, md: 6 }}
        align={{ base: 'flex-start', md: 'center' }}
      >
        <Text fontSize="sm">Are you looking for legacy staking?</Text>
        <SimpleGrid columns={3} w={{ base: 'full', md: 'auto' }} gap={2}>
          <Link
            isExternal
            href={`https://v1.${window.location.host}/stake/xjoe`}
          >
            <Button
              variant="outline"
              rightIcon={<ExternalLinkIcon ml={1} />}
              w={{ base: 'full', md: 'auto' }}
            >
              xJOE
            </Button>
          </Link>
          <Link
            isExternal
            href={`https://v1.${window.location.host}/stake/rjoe`}
          >
            <Button
              variant="outline"
              rightIcon={<ExternalLinkIcon ml={1} />}
              w={{ base: 'full', md: 'auto' }}
            >
              rJOE
            </Button>
          </Link>
          <Link
            isExternal
            href={`https://v1.${window.location.host}/stake/vejoe`}
          >
            <Button
              variant="outline"
              rightIcon={<ExternalLinkIcon ml={1} />}
              w={{ base: 'full', md: 'auto' }}
            >
              veJOE
            </Button>
          </Link>
        </SimpleGrid>
      </VStack>
    </Box>
  )
}

export default Stake
