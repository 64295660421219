import { SimpleGrid } from '@chakra-ui/react'
import AnalyticsStat from 'components/AnalyticsStat'
import React from 'react'
import { formattedNum } from 'utils/format'
import { formatUnits } from 'viem'

interface StakeAnalyticsGridProps {
  fees1D: number | undefined
  fees7D: number | undefined
  fees30D: number | undefined
  isLoading: boolean
  totalStaked: bigint | undefined
}

const StakeAnalyticsGrid = ({
  fees1D,
  fees7D,
  fees30D,
  isLoading,
  totalStaked
}: StakeAnalyticsGridProps) => {
  const fmtTotalStaked =
    totalStaked !== undefined
      ? `${formattedNum(formatUnits(totalStaked, 18))} JOE`
      : '--'

  const fmtFees1D =
    fees1D !== undefined ? formattedNum(fees1D, { usd: true }) : '--'
  const fmtFees7D =
    fees7D !== undefined ? formattedNum(fees7D, { usd: true }) : '--'
  const fmtFees30D =
    fees30D !== undefined ? formattedNum(fees30D, { usd: true }) : '--'

  return (
    <SimpleGrid columns={{ base: 2, md: 4 }} gap={{ base: 4, md: 6 }}>
      <AnalyticsStat
        title="Total Staked"
        value={fmtTotalStaked}
        isLoading={isLoading}
      />
      <AnalyticsStat title="1D Fees" value={fmtFees1D} isLoading={isLoading} />
      <AnalyticsStat title="7D Fees" value={fmtFees7D} isLoading={isLoading} />
      <AnalyticsStat
        title="30D Fees"
        value={fmtFees30D}
        isLoading={isLoading}
      />
    </SimpleGrid>
  )
}

export default StakeAnalyticsGrid
