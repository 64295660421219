import {
  Flex,
  Hide,
  Table,
  TableContainer,
  Tbody,
  Thead,
  Tr
} from '@chakra-ui/react'
import { PoolSortMethod } from 'hooks/pool/useSortedPools'
import PoolTableHeader from 'pages/Pool/PoolTableHeader'
import React from 'react'
import { VaultWithUserPosition } from 'types/vault'

import { vaultsListHeaders } from './constants'
import VaultMobileRow from './VaultMobileRow'
import VaultRow from './VaultRow'

interface VaultTableProps {
  filteredVaults: VaultWithUserPosition[]
  isSortDescending: boolean
  onHeaderClick: (method?: PoolSortMethod) => void
  sortMethod: PoolSortMethod
  vaults: VaultWithUserPosition[]
}

const VaultTable = ({
  filteredVaults,
  isSortDescending,
  onHeaderClick,
  sortMethod,
  vaults
}: VaultTableProps) => {
  return (
    <>
      <Hide below="md">
        <TableContainer
          bg="bgCard"
          borderRadius="2xl"
          border="1px solid"
          borderColor="border"
          p={4}
        >
          <Table variant="card">
            <Thead>
              <Tr>
                {vaultsListHeaders.map((header, i) => (
                  <PoolTableHeader
                    key={i}
                    selectedSortMethod={sortMethod}
                    isSortDescending={isSortDescending}
                    {...header}
                    onClick={() => onHeaderClick(header.sortMethod)}
                  />
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {filteredVaults.map((vault, i) => (
                <VaultRow key={i} vault={vault} />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Hide>
      <Hide above="md">
        <Flex flexDir="column" gap={2}>
          {vaults.map((vault, i) => (
            <VaultMobileRow key={i} vault={vault} />
          ))}
        </Flex>
      </Hide>
    </>
  )
}

export default VaultTable
