import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import {
  JOE_ADDRESS,
  SJOE_REWARD_TOKEN,
  STABLE_JOE_STAKING_ADDRESS,
  StableJoeStakingABI
} from '@traderjoe-xyz/sdk'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { formattedNum } from 'utils/format'
import { erc20Abi, formatUnits, getAddress, zeroAddress } from 'viem'
import {
  useAccount,
  useReadContracts,
  useSimulateContract,
  useWriteContract
} from 'wagmi'

const getSJoeContract = ({ chainId }: { chainId: ChainId }) => {
  const sJoeAddress = getAddress(STABLE_JOE_STAKING_ADDRESS[chainId])
  const sJoeContract = {
    abi: StableJoeStakingABI,
    address: sJoeAddress,
    chainId
  }

  const rewardToken = getAddress(SJOE_REWARD_TOKEN[chainId])

  return { rewardToken, sJoeAddress, sJoeContract }
}

interface UseSJoeStakingProps {
  chainId: ChainId
}

const useSJoeStaking = ({ chainId }: UseSJoeStakingProps) => {
  const { address: account } = useAccount()
  const { rewardToken, sJoeAddress, sJoeContract } = getSJoeContract({
    chainId
  })

  const joeAddress = getAddress(JOE_ADDRESS[chainId])
  const joeContract = {
    abi: erc20Abi,
    address: joeAddress,
    chainId
  }

  return useReadContracts({
    contracts: [
      {
        ...sJoeContract,
        functionName: 'depositFeePercent'
      },
      {
        ...joeContract,
        args: [sJoeAddress],
        functionName: 'balanceOf'
      },
      {
        ...sJoeContract,
        args: account && rewardToken ? [account, rewardToken] : undefined,
        functionName: 'pendingReward'
      },
      {
        ...sJoeContract,
        args: account && rewardToken ? [account, rewardToken] : undefined,
        functionName: 'getUserInfo'
      }
    ],
    query: {
      enabled: sJoeContract.address != zeroAddress,
      select: (data) => {
        return {
          depositFee: data?.[0]?.result,
          pendingRewards: data?.[2]?.result,
          sJoeUserBalance: data?.[3]?.result?.[0],
          totalStaked: data?.[1]?.result
        }
      }
    }
  })
}

const useSJoeClaimRewards = () => {
  const chainId = useChainId()
  const account = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { rewardToken, sJoeContract } = getSJoeContract({ chainId })

  // claim is deposit(0)
  const { data: config } = useSimulateContract({
    abi: sJoeContract.abi,
    address: sJoeContract.address,
    args: account && rewardToken ? [BigInt(0)] : undefined,
    chainId,
    functionName: 'deposit',
    query: {
      enabled:
        !!account &&
        !!rewardToken &&
        rewardToken != zeroAddress &&
        account.chainId === chainId,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data,
    isPending: isClaiming,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const transactionSummary = t`Claimed sJoe rewards`
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({
          description: transactionSummary,
          hash
        })
      }
    }
  })

  const claim = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransactionReceipt({
      hash: data
    })

  return {
    claim,
    isClaiming: isClaiming || isWaitingForTransaction,
    isSuccess
  }
}

interface UseSJoeDepositProps {
  amount?: bigint
  approved?: boolean
  onSuccess?: () => void
}

const useSJoeDeposit = ({
  amount,
  approved,
  onSuccess
}: UseSJoeDepositProps) => {
  const chainId = useChainId()
  const walletChainId = useAccount().chain?.id
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  // check if approved and args are valid
  const enabled = !!amount && approved && walletChainId === chainId

  const { data: config } = useSimulateContract({
    abi: StableJoeStakingABI,
    address: getAddress(STABLE_JOE_STAKING_ADDRESS[chainId]),
    args: amount ? [amount] : undefined,
    chainId,
    functionName: 'deposit',
    query: {
      enabled,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  // Note: assume JOE token is always 18 decimals
  const JOE_TOKEN_DECIMALS = 18

  const { data, isPending, writeContract } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        if (!amount) return
        const transactionSummary = t`Staked ${formattedNum(
          formatUnits(amount, JOE_TOKEN_DECIMALS)
        )} JOE`
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({
          description: transactionSummary,
          hash
        })
      }
    }
  })

  const deposit = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction } = useWaitForTransactionReceipt({
    hash: data,
    onTransactionSuccess: onSuccess
  })

  return {
    deposit,
    isLoading: isPending || isWaitingForTransaction
  }
}

const useSJoeWithdraw = ({ amount, onSuccess }: UseSJoeDepositProps) => {
  const chainId = useChainId()
  const walletChainId = useAccount().chain?.id
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data: config } = useSimulateContract({
    abi: StableJoeStakingABI,
    address: getAddress(STABLE_JOE_STAKING_ADDRESS[chainId]),
    args: amount ? [amount] : undefined,
    chainId,
    functionName: 'withdraw',
    query: {
      enabled: !!amount && walletChainId === chainId,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  // Note: assume JOE token is always 18 decimals
  const JOE_TOKEN_DECIMALS = 18

  const { data, isPending, writeContract } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        if (!amount) return
        const transactionSummary = t`Unstaked ${formattedNum(
          formatUnits(amount, JOE_TOKEN_DECIMALS)
        )} JOE`
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({
          description: transactionSummary,
          hash
        })
      }
    }
  })

  const withdraw = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransactionReceipt({
      hash: data,
      onTransactionSuccess: onSuccess
    })

  return {
    isLoading: isWaitingForTransaction || isPending,
    isSuccess,
    withdraw
  }
}

export { useSJoeClaimRewards, useSJoeDeposit, useSJoeStaking, useSJoeWithdraw }
