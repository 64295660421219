import {
  ChevronDownIcon,
  ChevronUpIcon,
  ExternalLinkIcon
} from '@chakra-ui/icons'
import {
  Box,
  Flex,
  HStack,
  Link,
  Tag,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import LBPairDistributionSection from 'components/LBPairDistributionSection'
import { STRATEGIES_INFO } from 'constants/vaults'
import useLBPairUserDistribution from 'hooks/pool/v2/useLBPairUserDistribution'
import useActiveChain from 'hooks/useActiveChain'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { AchievementIcon, CopyIcon } from 'theme/icons'
import { LeaderboardRowProps } from 'types/leaderboard'
import { shortenAddress } from 'utils/addresses'
import { formattedNum } from 'utils/format'
import { useAccount } from 'wagmi'

const LeaderboardRow = ({
  accruedFeesUsd,
  activeBinId,
  binStep,
  currency0,
  currency1,
  estimatedRewardsUsd,
  isExpanded,
  lbPairAddress,
  lbPairVersion,
  onCopyDistributionClick,
  onExpandCollapseClick,
  position,
  shareVolumeTradedUsd,
  userAddress
}: LeaderboardRowProps) => {
  const { isConnected } = useAccount()
  const { address: account } = useAccount()
  const chainId = useChainId()
  const { blockExplorers } = useActiveChain()

  const autoPoolName = STRATEGIES_INFO[chainId][userAddress]?.name
  const userName = autoPoolName ?? shortenAddress(userAddress, 6)

  const renderTrophy = (position: number | undefined) => {
    switch (position) {
      case 1:
        return <AchievementIcon fill="yellowBar" />
      case 2:
        return <AchievementIcon fill="gray.400" />
      case 3:
        return <AchievementIcon fill="yellow.600" />
      default:
        return null
    }
  }

  const isCurrentUser = account?.toLowerCase() === userAddress.toLowerCase()

  const {
    chartData,
    isLoading: isLoadingUserDistribution,
    numBins,
    totalTokensX,
    totalTokensY,
    userBalances
  } = useLBPairUserDistribution({
    activeBinId,
    binStep,
    currency0,
    currency1,
    enabled: isExpanded,
    lbPairAddress,
    lbPairVersion,
    owner: userAddress
  })

  return (
    <Tbody>
      <Tr
        data-cy={`leaderboard-row-${userAddress}`}
        role="group"
        onClick={onExpandCollapseClick}
        cursor="pointer"
      >
        <Td>{position}</Td>
        <Td>
          <HStack>
            {renderTrophy(position)}
            <Link
              aria-label="Link to user address on explorer"
              isExternal
              href={`${blockExplorers?.default.url}/address/${userAddress}`}
            >
              <Flex flexDir="row" alignItems="center" columnGap={2}>
                <Box>{userName}</Box>
                <ExternalLinkIcon />
              </Flex>
            </Link>
            {isCurrentUser ? (
              <Tag
                size="sm"
                bg="yellowBar"
                fontWeight="semibold"
                borderRadius="full"
              >
                YOU
              </Tag>
            ) : null}
          </HStack>
        </Td>
        <Td>
          {totalTokensX !== undefined && totalTokensY !== undefined ? (
            <Flex columnGap={2} justifyItems="start">
              <Text>
                {formattedNum(totalTokensX, {
                  allowDecimalsOver1000: true,
                  allowSmallDecimals: true,
                  places: 2
                })}
                <Text as="span" fontWeight="normal">
                  {' '}
                  {currency0?.symbol}
                </Text>
              </Text>
              <Text>
                {formattedNum(totalTokensY, {
                  allowDecimalsOver1000: true,
                  allowSmallDecimals: true,
                  places: 2
                })}
                <Text as="span" fontWeight="normal">
                  {' '}
                  {currency1?.symbol}
                </Text>
              </Text>
              <Text>
                {numBins}
                <Text as="span" fontWeight="normal">
                  {' '}
                  bins
                </Text>
              </Text>
            </Flex>
          ) : null}
        </Td>
        <Td textAlign="right" fontWeight="normal">
          {formattedNum(shareVolumeTradedUsd, { usd: true })}
        </Td>
        <Td textAlign="right" fontWeight="normal">
          {formattedNum(accruedFeesUsd, { usd: true })}
        </Td>
        {estimatedRewardsUsd ? (
          <Td textAlign="right" fontWeight="normal">
            <Tooltip
              label={t`Estimated Rewards based on fees earned may differ from final reward calculations`}
            >
              <Text
                fontWeight="normal"
                color="textSecondary"
                textDecor="dotted underline"
                cursor="help"
              >
                {formattedNum(estimatedRewardsUsd, { usd: true })}
              </Text>
            </Tooltip>
          </Td>
        ) : null}
        <Td textAlign="right">
          {isExpanded ? (
            <ChevronUpIcon boxSize={5} color="textSecondary" />
          ) : (
            <ChevronDownIcon boxSize={5} color="textSecondary" />
          )}
        </Td>
      </Tr>
      <Tr _hover={{}}>
        {isExpanded ? (
          <Td colSpan={estimatedRewardsUsd ? 7 : 6} _hover={{}}>
            <LBPairDistributionSection
              title="User Distribution"
              currency0={currency0}
              currency1={currency1}
              data={chartData}
              activeBinId={activeBinId}
              isLoading={isLoadingUserDistribution}
              headingActionButtonProps={{
                children: isConnected ? 'Copy distribution' : 'Connect to copy',
                color: 'textSecondary',
                leftIcon: <CopyIcon fill="textSecondary" />,
                size: 'xs',
                variant: 'ghost'
              }}
              onHeadingActionButtonClick={() =>
                onCopyDistributionClick(userAddress, userBalances)
              }
            />
          </Td>
        ) : null}
      </Tr>
      <Tr h={2} />
    </Tbody>
  )
}

export default LeaderboardRow
