import {
  HStack,
  LinkBox,
  LinkOverlay,
  Skeleton,
  Td,
  Text,
  Tr
} from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'
import { MemeFarm } from 'types/dexbarn'
import { formattedNum } from 'utils/format'

interface MemeFarmRowProps {
  farm: MemeFarm
  isLoadingUserPositions: boolean
  memeTokenSupplyStakedPercentage?: number
}

const MemeFarmRow = ({
  farm,
  isLoadingUserPositions,
  memeTokenSupplyStakedPercentage
}: MemeFarmRowProps) => {
  return (
    <LinkBox as={Tr} transform="scale(1)" cursor="pointer" role="group">
      <Td>
        <LinkOverlay
          aria-label={`${farm.memeToken.symbol} meme farm`}
          href={`/avalanche/stek/${farm.memeToken.tokenAddress}`}
        />
        <HStack>
          <CurrencyLogo
            address={farm.memeToken.tokenAddress}
            symbol={farm.memeToken.symbol}
            boxSize={6}
          />
          <Text>{farm.memeToken.symbol}</Text>
        </HStack>
      </Td>
      <Td py={3}>
        <Text>
          {farm.staked
            ? `${formattedNum(farm.staked, { places: 2 })} ${
                farm.memeToken.symbol
              }`
            : '--'}
        </Text>
      </Td>
      <Td py={3}>
        <Text>
          {memeTokenSupplyStakedPercentage
            ? `${formattedNum(memeTokenSupplyStakedPercentage, { places: 2 })}%`
            : '--'}
        </Text>
      </Td>
      <Td py={3}>
        <Skeleton isLoaded={!isLoadingUserPositions}>
          {farm.userStaked ? (
            <Text>{`${formattedNum(farm.userStaked, {
              places: 2
            })} ${farm.memeToken.symbol}`}</Text>
          ) : (
            '--'
          )}
        </Skeleton>
      </Td>
    </LinkBox>
  )
}

export default MemeFarmRow
