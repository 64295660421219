import { LinkBox, LinkOverlay, Td, Tr } from '@chakra-ui/react'
import useChainId from 'hooks/useChainId'
import PoolTableNameColumn from 'pages/Pool/PoolTableNameColumn'
import React from 'react'
import { LBPool } from 'types/pool'
import { formattedNum } from 'utils/format'
import { getPoolDetailV2Url } from 'utils/poolUrl'

interface BoostPoolMobileRowProps {
  lbPool: LBPool
}

const BoostPoolMobileRow = ({ lbPool }: BoostPoolMobileRowProps) => {
  const chainId = useChainId()
  const href = getPoolDetailV2Url(
    lbPool.lbPoolVersion,
    lbPool.lbBinStep,
    chainId,
    lbPool.tokenX.address,
    lbPool.tokenY.address
  )

  const fmtRewardsApr = `${(
    (lbPool.lbRewards?.reduce((acc, reward) => {
      return acc + (reward.rewardsApr || 0)
    }, 0) || 0) * 100
  ).toFixed(2)}%`

  return (
    <LinkBox
      p={0}
      as={Tr}
      transform="scale(1)"
      cursor="pointer"
      _hover={{ bg: 'hover' }}
      bg="white"
    >
      <Td px={0} pl={4} py={2}>
        <LinkOverlay
          aria-label={`Link to pool page for ${lbPool.tokenX.symbol}-${lbPool.tokenY.symbol}`}
          href={href}
        />

        <PoolTableNameColumn token0={lbPool.tokenX} token1={lbPool.tokenY} />
      </Td>
      <Td px={0} py={2}>
        {formattedNum(lbPool.liquidityUsd, { usd: true })}
      </Td>
      <Td textAlign="right" px={0} py={2} pr={4}>
        {fmtRewardsApr}
      </Td>
    </LinkBox>
  )
}

export default BoostPoolMobileRow
