import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Button,
  Heading,
  HStack,
  Image,
  Link,
  SimpleGrid,
  Skeleton,
  Text,
  VStack
} from '@chakra-ui/react'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { getChain } from 'utils/chains'
import { formattedNum } from 'utils/format'
import { formatUnits } from 'viem'

interface StakeCardProps {
  apr30d: number | undefined
  chainId: ChainId.ARBITRUM_ONE | ChainId.AVALANCHE
  fees30d: number | undefined
  isLoading: boolean | undefined
  totalStaked: bigint | undefined
}

const StakeCard = ({
  apr30d,
  chainId,
  fees30d,
  isLoading,
  totalStaked
}: StakeCardProps) => {
  const chain = getChain(chainId)
  if (!chain) return null

  const fmtTotalStaked =
    totalStaked !== undefined
      ? `${formattedNum(formatUnits(totalStaked, 18))} JOE`
      : ''

  const fmtFees30D =
    fees30d !== undefined ? formattedNum(fees30d, { usd: true }) : ''

  const fmtApr30D =
    apr30d !== undefined ? `${formattedNum(apr30d * 100, { places: 2 })}%` : ''

  return (
    <Link
      as={NavLink}
      to={`/${chain.slug}/stake/sjoe`}
      transition="all 0.3s"
      _hover={{
        '& .deposit-button': {
          bg: 'accent.500',
          borderColor: 'accent.500',
          color: 'white'
        },
        _dark: {
          '& .deposit-button': {
            bg: 'accent.500',
            borderColor: 'accent.500',
            color: 'white'
          }
        },
        textDecor: 'none',
        transform: 'translateY(-4px)'
      }}
      bg="bgCard"
      borderRadius="2xl"
      border="1px solid"
      borderColor="border"
    >
      <HStack
        p={{ base: 4, md: 8 }}
        bg="bgCardElement"
        borderRadius="2xl"
        spacing={3}
        justify="space-between"
      >
        <HStack>
          <Image src={chain.iconUrl} alt={chain.name} />
          <Heading size="md">{chain.name}</Heading>
        </HStack>

        <Button
          variant="outline"
          rightIcon={<ArrowForwardIcon />}
          size="lg"
          borderRadius="full"
          className="deposit-button"
          transition="all 0.3s"
        >
          Deposit
        </Button>
      </HStack>

      <SimpleGrid columns={3} p={{ base: 4, md: 8 }}>
        <VStack align="flex-start" spacing={0}>
          <Text fontSize="sm" color="textSecondary">
            TVL
          </Text>
          <Skeleton isLoaded={!isLoading}>
            <Text fontWeight="semibold">{fmtTotalStaked || '--'}</Text>
          </Skeleton>
        </VStack>

        <VStack align="flex-start" spacing={0}>
          <Text fontSize="sm" color="textSecondary">
            30D Fees
          </Text>
          <Skeleton isLoaded={!isLoading}>
            <Text fontWeight="semibold">{fmtFees30D || '--'}</Text>
          </Skeleton>
        </VStack>

        <VStack align="flex-start" spacing={0}>
          <Text fontSize="sm" color="textSecondary">
            30D APR
          </Text>
          <Skeleton isLoaded={!isLoading}>
            <Text fontWeight="semibold">{fmtApr30D || '--'}</Text>
          </Skeleton>
        </VStack>
      </SimpleGrid>
    </Link>
  )
}

export default StakeCard
