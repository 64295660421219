import { Box, Flex, Td, Tr } from '@chakra-ui/react'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { VaultWithUserPosition } from 'types/vault'
import { getChainSlug } from 'utils/chains'
import { formattedNum } from 'utils/format'
import { getFarmRowFormattedValues, getUserVaultBalanceUSD } from 'utils/vault'

import PoolTableNameColumn from '../PoolTableNameColumn'
import UserPositionValueTd from './UserPositionValueTd'

interface VaultPositionRowProps {
  vault: VaultWithUserPosition
}

const VaultPositionRow = ({ vault }: VaultPositionRowProps) => {
  const navigate = useNavigate()

  const chainId = useChainId()
  const userBalanceUSD = getUserVaultBalanceUSD(vault)
  const { myFarmDepositUSD } = getFarmRowFormattedValues(vault)

  const totalDeposit = userBalanceUSD ? userBalanceUSD + myFarmDepositUSD : 0

  const fmtTotalFundsDeposit =
    totalDeposit > 0
      ? formattedNum(totalDeposit, {
          usd: true
        })
      : '--'

  const fmtFarmDeposit = myFarmDepositUSD
    ? formattedNum(myFarmDepositUSD, {
        usd: true
      })
    : ''

  return (
    <Tr
      cursor="pointer"
      _hover={{
        bg: 'hover'
      }}
      aria-label={`${vault.tokenX.symbol}-${vault.tokenY.symbol} auto pool user position`}
      transform="scale(1)"
      role="group"
      onClick={() => navigate(`/${getChainSlug(chainId)}/vault/${vault.id}`)}
    >
      <Td>
        <PoolTableNameColumn
          token0={vault.tokenX}
          token1={vault.tokenY}
          fees={`${vault.lbPair.baseFeePct}%`}
        />
      </Td>
      <UserPositionValueTd
        symbol0={vault.tokenX.symbol}
        symbol1={vault.tokenY.symbol}
        pooledTokens0={vault.userPositions.vaultBalance?.fmtAmountX}
        pooledTokens1={vault.userPositions.vaultBalance?.fmtAmountY}
      />
      <Td textAlign="right">
        <Flex justifyContent="flex-end" columnGap={4}>
          <Box as="span">{fmtTotalFundsDeposit}</Box>
          {myFarmDepositUSD > 0 && (
            <Box
              textDecoration="dotted underline"
              textColor="textSecondaryLight"
              fontWeight="normal"
            >
              <TouchFriendlyTooltip label="Farm Deposit USD">
                {fmtFarmDeposit}
              </TouchFriendlyTooltip>
            </Box>
          )}
        </Flex>
      </Td>
    </Tr>
  )
}

export default VaultPositionRow
