import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import AppChainSelector from 'components/AppChainSelector'
import ActivityList from 'components/WalletMenu/ActivityScreen/ActivityList'
import ConnnectedAccount from 'components/WalletMenu/ConnectedScreen/ConnectedAccount'
import WalletBalances from 'components/WalletMenu/ConnectedScreen/WalletBalances'
import SettingsScreen from 'components/WalletMenu/SettingScreen'
import React, { useEffect, useState } from 'react'
import { DisconnectIcon, SettingsIcon, WalletIcon } from 'theme/icons'
import { useDisconnect } from 'wagmi'

interface MobileAccountMenuProps {
  walletAddress: string
}

const MobileAccountMenu = ({ walletAddress }: MobileAccountMenuProps) => {
  const { disconnect } = useDisconnect()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [walletMenuState, setWalletMenuState] = useState<
    'default' | 'settings'
  >('default')

  const renderWalletMenu = () => {
    switch (walletMenuState) {
      case 'default':
        return (
          <>
            <ConnnectedAccount
              walletAddress={walletAddress}
              stackProps={{ py: 4 }}
            />
            <Flex gap={2}>
              <AppChainSelector menuButtonProps={{ flex: 1 }} />
              <IconButton
                aria-label="go to settings"
                onClick={() => setWalletMenuState('settings')}
                variant="outline"
                icon={<SettingsIcon />}
                boxSize={12}
              />
              <IconButton
                aria-label="disconnect wallet"
                onClick={() => disconnect()}
                variant="outline"
                icon={<DisconnectIcon />}
                boxSize={12}
              />
            </Flex>
            <Divider my={6} />
            <Tabs variant="soft-rounded" size="lg" isFitted>
              <TabList>
                <Tab w="full">
                  <Trans>Wallet</Trans>
                </Tab>
                <Tab>
                  <Trans>Activity</Trans>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel px={0}>
                  <WalletBalances
                    isVisible={isOpen}
                    onTokenSelected={onClose}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <ActivityList walletAddress={walletAddress} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )
      case 'settings':
        return (
          <SettingsScreen
            headerStackProps={{ pb: 2 }}
            onBackClick={() => setWalletMenuState('default')}
            boxProps={{ p: 0 }}
          />
        )
    }
  }

  useEffect(() => {
    if (isOpen) {
      setWalletMenuState('default')
    }
  }, [isOpen])

  return (
    <>
      <Button w="full" bg="transparent" onClick={onOpen}>
        <Flex alignItems="center" pos="relative">
          <WalletIcon fill="textPrimary" w="21px" h="18px" />
          <Box
            top={-1}
            boxSize={2}
            right={-3}
            position="absolute"
            borderRadius="full"
            bgColor="green.300"
          />
        </Flex>
      </Button>
      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent
          pb={4}
          borderTopLeftRadius={24}
          borderTopRightRadius={24}
          bg="bgPrimary"
        >
          <DrawerCloseButton />
          <DrawerBody>{renderWalletMenu()}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MobileAccountMenu
