import { HStack, Image, Tab, TabList, Tabs, Text } from '@chakra-ui/react'
import { JoeChain } from 'constants/chains'
import React from 'react'
import { Chain } from 'viem'

interface ChainTabsProps {
  chains: (Chain & JoeChain)[]
  index: number
  onChange: (index: number) => void
}

const ChainTabs = ({ chains, index, onChange }: ChainTabsProps) => {
  return (
    <Tabs variant="solid" index={index} onChange={onChange}>
      <TabList>
        {chains.map((chain, i) => (
          <Tab key={i} data-cy={`chain-tab-${chain.slug}`}>
            <HStack>
              <Image alt="chain-icon" src={chain.iconUrl} boxSize={5} />
              <Text>{chain.name}</Text>
            </HStack>
          </Tab>
        ))}
      </TabList>
    </Tabs>
  )
}

export default ChainTabs
