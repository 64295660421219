import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys
)

const baseStyle = {
  tab: {
    color: 'blue',
    fontWeight: 'semibold'
  }
}

const enclosed = {
  tab: {
    _selected: {
      _dark: { bg: 'joeDark.700' },
      bg: 'joeLight.300',
      border: 0,
      color: 'textPrimary'
    },
    border: 0,
    borderTopRadius: '2xl',
    color: 'textSecondary',
    fontWeight: 'semibold',
    padding: 4
  },
  tablist: {
    border: 0
  },
  tabpanels: {
    _dark: { bg: 'joeDark.700' },
    bg: 'joeLight.300'
  }
}

const softRounded = {
  tab: {
    _dark: {
      _selected: { bg: '#1d1e2a', color: 'white' },
      color: 'joeDark.300'
    },
    _selected: {
      bg: '#ebebff',
      color: 'textPrimary'
    },
    borderRadius: 'full',
    color: 'joeLight.700',
    fontSize: 'md',
    paddingX: 3
  },
  tablist: {
    _dark: {
      bg: 'joeDark.500'
    },
    bg: '#e2e2fd',
    borderRadius: 'full',
    height: 12,
    padding: '3px',
    size: { base: 'sm', md: 'md' }
  }
}

const solid = {
  tab: {
    _selected: {
      bg: 'bgSecondary',
      borderColor: 'transparent',
      textColor: 'textPrimary'
    },
    border: '1px solid',
    borderColor: 'border',
    borderRadius: 'full',
    h: '48px',
    textColor: 'textSecondary'
  },
  tablist: {
    gap: 2
  }
}

export const tabsTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    enclosed,
    'soft-rounded': softRounded,
    solid
  }
})
