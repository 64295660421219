import { HStack, LinkBox, LinkOverlay, Td, Text, Tr } from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'
import { formattedNum } from 'utils/format'
import { TokenRow } from 'utils/tokensRanking'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

const TokensRankingMobileRow = ({
  address,
  chainId,
  href,
  price,
  symbol,
  volume
}: TokenRow) => {
  return (
    <LinkBox
      p={0}
      as={Tr}
      transform="scale(1)"
      cursor="pointer"
      _hover={{ bg: 'hover' }}
      bg="white"
    >
      <Td px={0} pl={4} py={2}>
        <LinkOverlay
          aria-label={`Link to trade page for ${address}`}
          href={href}
        />
        <HStack>
          <CurrencyLogo
            address={
              isWrappedNativeCurrency(address, chainId) ? undefined : address
            }
            symbol={symbol}
            boxSize={6}
          />
          <Text>{symbol}</Text>
        </HStack>
      </Td>
      <Td px={0} py={2}>
        {formattedNum(volume, { usd: true })}
      </Td>
      <Td textAlign="right" px={0} py={2} pr={4}>
        <Text>{formattedNum(price, { usd: true })}</Text>
      </Td>
    </LinkBox>
  )
}

export default TokensRankingMobileRow
