import { HStack, LinkBox, LinkOverlay, Td, Tr } from '@chakra-ui/react'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { LBPoolVersion } from 'types/pool'
import { formattedNum } from 'utils/format'
import { getPoolDetailV2Url } from 'utils/poolUrl'

import PoolTableNameColumn from '../PoolTableNameColumn'

export interface UserRewardRowProps {
  lbPair: {
    address: string
    binStep: number
    version: LBPoolVersion
  }
  rewards: {
    amount: number
    tokenAddress: string
    tokenDecimals: number
    tokenSymbol: string
  }[]
  tokenX: {
    address: string
    symbol: string
  }
  tokenY: {
    address: string
    symbol: string
  }
}

const UserRewardRow = ({
  lbPair,
  rewards,
  tokenX,
  tokenY
}: UserRewardRowProps) => {
  const chainId = useChainId()

  return (
    <LinkBox as={Tr} cursor="pointer" transform="scale(1)" role="group">
      <Td>
        <LinkOverlay
          aria-label={`${tokenX.symbol} - ${tokenY.symbol} liquidity pool rewards`}
          href={getPoolDetailV2Url(
            lbPair.version,
            lbPair.binStep,
            chainId,
            tokenX.address,
            tokenY.address
          )}
        />
        <PoolTableNameColumn token0={tokenX} token1={tokenY} />
      </Td>

      <Td textAlign="right">
        <HStack justify="flex-end">
          {rewards.map((reward) => {
            return (
              <span key={reward.tokenAddress}>
                {formattedNum(reward.amount, {
                  places: 5
                })}{' '}
                {reward.tokenSymbol}
              </span>
            )
          })}
        </HStack>
      </Td>
    </LinkBox>
  )
}

export default UserRewardRow
