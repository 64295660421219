import { Box, Heading, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import useGetStakingInfo from 'hooks/stake/useGetStakingInfo'
import StakeCard from 'pages/Stake/StakeCard'
import React from 'react'

const StakingSection = () => {
  const {
    arbitrumApr30D,
    arbitrumPayoutDayDatas,
    arbitrumStakingData,
    avalancheApr30D,
    avalanchePayoutDayDatas,
    avalancheStakingData,
    isLoading
  } = useGetStakingInfo()

  return (
    <Box w="full" px={{ base: 4, md: 0 }}>
      <VStack align="flex-start" spacing={{ base: 0, md: 2 }}>
        <Heading size="lg">
          <Trans>Stake</Trans>
        </Heading>
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>Stake your JOE tokens and earn more</Trans>
        </Text>
      </VStack>

      <SimpleGrid columns={{ base: 1, md: 2 }} gap={4} pt={{ base: 4, md: 6 }}>
        <StakeCard
          chainId={ChainId.AVALANCHE}
          apr30d={avalancheApr30D}
          fees30d={avalanchePayoutDayDatas?.feesUsd30D}
          totalStaked={avalancheStakingData?.totalStaked}
          isLoading={isLoading}
        />
        <StakeCard
          chainId={ChainId.ARBITRUM_ONE}
          apr30d={arbitrumApr30D}
          fees30d={arbitrumPayoutDayDatas?.feesUsd30D}
          totalStaked={arbitrumStakingData?.totalStaked}
          isLoading={isLoading}
        />
      </SimpleGrid>
    </Box>
  )
}

export default StakingSection
