import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Link, Text } from '@chakra-ui/react'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import useChainId from 'hooks/useChainId'
import React from 'react'

const LegacyPoolsText = () => {
  const chainId = useChainId()

  if (chainId === ChainId.ETHEREUM || chainId === ChainId.BNB_CHAIN) {
    return null
  }

  return (
    <Text align="center" textColor="textSecondary">
      Withdraw your liquidity from legacy pools on{' '}
      <Link
        fontWeight="semibold"
        isExternal
        href={`https://${window.location.host}/ethereum/pool?tab=my-pools`}
      >
        Ethereum <ExternalLinkIcon mb={1} />
      </Link>{' '}
      and{' '}
      <Link
        fontWeight="semibold"
        isExternal
        href={`https://${window.location.host}/bnb-chain/pool?tab=my-pools`}
      >
        BNB Chain <ExternalLinkIcon mb={1} />
      </Link>
      .
    </Text>
  )
}

export default LegacyPoolsText
