import { Box, Center, Heading, Spinner, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useGetPopularItems } from 'hooks/joebarn/useGetPopularItems'
import React from 'react'

import JoepegPreview from './JoepegPreview'

const JoepegSection = () => {
  const { data: collections, isLoading } = useGetPopularItems()

  return (
    <Box w="full" px={{ base: 4, md: 0 }}>
      <VStack
        align="flex-start"
        spacing={{ base: 0, md: 2 }}
        pb={{ base: 4, md: 6 }}
      >
        <Heading size="lg">
          <Trans>Popular on Joepegs</Trans>
        </Heading>
        <Text textColor="textSecondary" fontSize="sm">
          <Trans>Enjoy our roundup of popular collections</Trans>
        </Text>
      </VStack>

      {isLoading ? (
        <Center h="120px">
          <Spinner />
        </Center>
      ) : (
        <JoepegPreview collections={collections} />
      )}
    </Box>
  )
}

export default JoepegSection
