import bannerUrl from 'assets/banner.png'
import React, { useEffect } from 'react'

interface PageHelmetProps {
  url: string
  description?: string
  title?: string
}

const PageHelmet: React.FC<PageHelmetProps> = ({ description, title, url }) => {
  useEffect(() => {
    const defaultTitle =
      "Let's F***ing Joe | Instant, Low-Cost Crypto Exchange for All Traders"
    const defaultDescription =
      "Let's F***ing Joe (formerly Trader Joe) is a leading decentralized exchange. Fast, low-cost trading. Simple solutions for everyday traders. $120bn trade volume. Trade crypto instantly, securely."

    // Set the title
    document.title = title ?? defaultTitle

    // Define meta tags
    const metaTags = [
      { content: description ?? defaultDescription, name: 'description' },
      { content: 'website', property: 'og:type' },
      { content: `https://lfj.gg/${url}`, property: 'og:url' },
      { content: title ?? defaultTitle, property: 'og:title' },
      {
        content: description ?? defaultDescription,
        property: 'og:description'
      },
      { content: '1200', property: 'og:image:width' },
      { content: '630', property: 'og:image:height' },
      { content: bannerUrl, property: 'og:image' },
      { content: 'summary_large_image', name: 'twitter:card' },
      { content: `https://lfj.gg/${url}`, property: 'twitter:url' },
      { content: title ?? defaultTitle, property: 'twitter:title' },
      {
        content: description ?? defaultDescription,
        property: 'twitter:description'
      },
      { content: bannerUrl, property: 'twitter:image' }
    ]

    document.documentElement.setAttribute('prefix', 'og: http://ogp.me/ns#')

    // Create and append meta tags
    const addedTags = metaTags.map(({ content, name, property }) => {
      const tag = document.createElement('meta')
      if (name) tag.setAttribute('name', name)
      if (property) tag.setAttribute('property', property)
      tag.content = content
      document.head.appendChild(tag)
      return tag
    })

    // Cleanup function to remove added tags when component unmounts
    return () => {
      addedTags.forEach((tag) => document.head.removeChild(tag))
      document.documentElement.removeAttribute('prefix')
    }
  }, [title, description, url])

  return null
}

export default PageHelmet
