import { tableAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  td: {
    fontSize: 'sm',
    fontWeight: 'bold'
  },
  th: {
    color: 'textSecondary',
    fontWeight: 'normal'
  }
})

const thStyle = {
  _dark: {
    color: 'joeDark.300'
  },
  borderBottom: '0',
  color: 'joeLight.700',
  py: 4
}

const variantSimple = definePartsStyle(() => ({
  td: {
    borderBottom: '0'
  },
  th: thStyle
}))

const variantCard = definePartsStyle(() => ({
  table: {
    background: 'bgCard',
    borderCollapse: 'separate',
    borderRadius: 'xl',
    borderSpacing: '0 8px',
    my: '-8px',
    overflow: 'hidden'
  },
  tbody: {
    tr: {
      _hover: {
        td: {
          '&:first-of-type': {
            borderBottomLeftRadius: '9999px',
            borderTopLeftRadius: '9999px'
          },
          '&:last-child': {
            borderBottomRightRadius: '9999px',
            borderTopRightRadius: '9999px'
          }
        }
      },
      td: {
        '&:first-of-type': {
          borderBottomLeftRadius: '9999px',
          borderTopLeftRadius: '9999px'
        },
        '&:last-child': {
          borderBottomRightRadius: '9999px',
          borderTopRightRadius: '9999px'
        },
        py: '12px'
      }
    }
  },
  td: {
    borderBottom: '0',
    transition: 'background-color 0.2s'
  },
  thead: {
    '& th': {
      paddingBottom: '16px',
      paddingTop: '16px'
    },
    '& tr:first-of-type th:first-of-type': {
      borderBottomLeftRadius: '9999px',
      borderTopLeftRadius: '9999px'
    },
    '& tr:first-of-type th:last-of-type': {
      borderBottomRightRadius: '9999px',
      borderTopRightRadius: '9999px'
    },
    bg: 'bgCardElement'
  },
  tr: {
    _hover: {
      td: {
        bg: 'hover'
      }
    }
  }
}))

export const tableTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { card: variantCard, simple: variantSimple }
})
